import React from "react";
import { baseImagePath } from "../../../utility/functions";
import { Link, useLocation } from "react-router-dom";
import { JOIN_US, routes, SOCIAL_MEDIA_URLS } from "../../../utility/constant";

const Footer = (props) => {
  const history = useLocation();
  return (
    <footer className="footer">
      <div className="container">
        <div className="row">
          <div className="col-sm-6 col-lg-3">
            <div className="footer-logo">
              <Link className="footer-logo__link" to={routes.HOME}>
                <img
                  className="footer-logo__img"
                  src={baseImagePath("custom/logo-light-white.png")}
                  alt="logo"
                />
              </Link>
            </div>
            {/* <!-- footer socials start--> */}
            <ul className="footer-socials">
              <li className="footer-socials__item">
                <a
                  className="footer-socials__link"
                  target={"_blank"}
                  rel="noreferrer"
                  href={SOCIAL_MEDIA_URLS.INSTAGRAM}
                >
                  <i className="fa fa-instagram" aria-hidden="true"></i>
                </a>
              </li>
              <li className="footer-socials__item">
                <a
                  className="footer-socials__link"
                  target={"_blank"}
                  rel="noreferrer"
                  href={SOCIAL_MEDIA_URLS.YOUTUBE}
                >
                  <i className="fa fa-youtube" aria-hidden="true"></i>
                </a>
              </li>
              {/* <li className="footer-socials__item"><a className="footer-socials__link" href="#"><i className="fa fa-google-plus" aria-hidden="true"></i></a></li> */}
              <li className="footer-socials__item">
                <a
                  className="footer-socials__link"
                  target={"_blank"}
                  rel="noreferrer"
                  href={SOCIAL_MEDIA_URLS.FB}
                >
                  <i className="fa fa-facebook" aria-hidden="true"></i>
                </a>
              </li>
            </ul>
            {/* <!-- footer socials end--> */}
          </div>
          <div className="col-sm-6 col-lg-3">
            <h4 className="footer__title">Contact Us</h4>
            <div className="footer-contacts">
              <p className="footer-contacts__address">
                12, Saravana St, Parthasarathi Puram, T. Nagar, Chennai, Tamil
                Nadu 600017
              </p>
              <p className="footer-contacts__phone">
                Phone: <a href="tel:+914424312096">+91 44 2431 2096</a>
              </p>
              <p className="footer-contacts__mail">
                Email:{" "}
                <a href="mailto:updates@ryametro.com">updates@ryametro.com</a>
              </p>
            </div>
          </div>
          <div className="col-sm-6 col-lg-3">
            <h4 className="footer__title">Menu & Links</h4>
            {/* <!-- footer nav start--> */}
            <nav>
              <ul className="footer-menu">
                <li
                  className={
                    routes.HOME.includes(history.pathname) &&
                    !window.location.hash
                      ? "footer-menu__item footer-menu__item--active"
                      : "footer-menu__item"
                  }
                >
                  <Link className="footer-menu__link" to={routes.HOME}>
                    Home
                  </Link>
                </li>
                {/* <li
                  className={
                    routes.BLOG.includes(history.pathname) &&
                    window.location.hash === `#${routes.BLOG_HASH}`
                      ? "footer-menu__item footer-menu__item--active"
                      : "footer-menu__item"
                  }
                >
                  <Link className="footer-menu__link" to={routes.BLOG}>
                    Blog
                  </Link>
                </li> */}
                <li
                  className={
                    history.pathname.includes(routes.ABOUT_US)
                      ? "footer-menu__item footer-menu__item--active"
                      : "footer-menu__item"
                  }
                >
                  <Link className="footer-menu__link" to={routes.ABOUT_US}>
                    About Us
                  </Link>
                </li>
                {/* <li className="footer-menu__item"><a className="footer-menu__link" href="#">Contact Us</a></li> */}
                <li
                  className={
                    routes.INFRASTRUCTURE_PAGE.includes(history.pathname)
                      ? "footer-menu__item footer-menu__item--active"
                      : "footer-menu__item"
                  }
                >
                  <Link
                    className="footer-menu__link"
                    to={routes.INFRASTRUCTURE_PAGE}
                  >
                    Infrastructure
                  </Link>
                </li>
                <li
                  className={
                    routes.EVENT_GALLERY.includes(history.pathname) &&
                    window.location.hash === `#${routes.EVENT_GALLERY_HASH}`
                      ? "footer-menu__item footer-menu__item--active"
                      : "footer-menu__item"
                  }
                >
                  <Link className="footer-menu__link" to={routes.EVENT_GALLERY}>
                    Event Gallery
                  </Link>
                </li>
                <li
                  className={
                    history.pathname.includes(routes.SOCIAL_PROJECTS)
                      ? "footer-menu__item footer-menu__item--active"
                      : "footer-menu__item"
                  }
                >
                  <Link
                    className="footer-menu__link"
                    to={routes.SOCIAL_PROJECTS}
                  >
                    Social Projects
                  </Link>
                </li>
              </ul>
            </nav>
            {/* <!-- footer nav end--> */}
          </div>
          <div className="col-sm-6 col-lg-3">
            <h4 className="footer__title">Join Us</h4>
            <p>
              To fill your lives with fun, joy, fellowship and non-stop
              entertainment
            </p>
            {JOIN_US.isFormActive === "Yes" ? (
              <a
                className="button footer__button button--filled"
                href={JOIN_US.formURL}
              >
                Join Us
              </a>
            ) : (
              <button
                data-toggle="modal"
                data-target="#joinUsModal"
                className="button footer__button button--filled naked_btn"
                type="button"
              >
                Join Us
              </button>
            )}
          </div>
        </div>
        <div className="row align-items-baseline">
          <div className="col-md-6">
            <p className="footer-copyright">V 1.8</p>
          </div>
          <div className="col-md-6">
            <div className="footer-privacy">
              <Link className="footer-privacy__link" to={routes.PRIVACY_POLICY}>
                Privacy Policy
              </Link>
              <span className="footer-privacy__divider">|</span>
              <Link
                className="footer-privacy__link"
                to={routes.TERMS_AND_CONDITIONS}
              >
                Terms and Conditions
              </Link>
              <span className="footer-privacy__divider">|</span>
              <Link className="footer-privacy__link" to={routes.REFUND_RETURNS}>
                Return &amp; Refunds
              </Link>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
