import React from "react";
import { JOIN_US, routes } from "../../../utility/constant";
import { baseImagePath } from "../../../utility/functions";
import { Link, useLocation } from "react-router-dom";
import "./Header.css";

const Header = (props) => {
  const history = useLocation();
  return (
    <header className="header header--front">
      <div className="container-fluid">
        <div className="row no-gutters justify-content-between">
          <div className="col-auto d-flex align-items-center">
            {/* <div
              onClick={() => {
                var dropdown = window.$(".aside-dropdown");
                dropdown.addClass("aside-dropdown--active");
              }}
              className="dropdown-trigger d-none d-sm-block"
            >
              <div className="dropdown-trigger__item"></div>
            </div> */}
            <div className="header-logo ml-5">
              <Link className="header-logo__link" to={routes.HOME}>
                <img
                  className="header-logo__img"
                  src={baseImagePath("custom/logo-light.png")}
                  alt="logo"
                />
              </Link>
            </div>
          </div>
          <div className="col-auto">
            {/* <!-- main menu start--> */}
            <nav>
              <ul className="main-menu">
                <li
                  className={
                    routes.HOME.includes(history.pathname) &&
                    !window.location.hash
                      ? "main-menu__item main-menu__item--active"
                      : "main-menu__item"
                  }
                >
                  <Link className="main-menu__link" to={routes.HOME}>
                    <span>Home</span>
                  </Link>
                  {/* <!-- sub menu start--> */}
                  {/* <ul className="main-menu__sub-list">
                                        <li><a href="../index.html"><span>01 Home Color Style</span></a></li>
                                        <li><a href="../front_2.html"><span>02 Home Color Style</span></a></li>
                                        <li><a href="../front_3.html"><span>03 Home Color Style</span></a></li>
                                        <li className="item--active"><a href="index.html"><span>04 Home Monochrome</span></a></li>
                                        <li><a href="front_2.html"><span>05 Home Monochrome</span></a></li>
                                        <li><a href="front_3.html"><span>06 Home Monochrome</span></a></li>
                                    </ul> */}
                  {/* <!-- sub menu end--> */}
                </li>
                <li
                  className={
                    history.pathname.includes(routes.ABOUT_US)
                      ? "main-menu__item main-menu__item--active"
                      : "main-menu__item"
                  }
                >
                  <Link className="main-menu__link" to={routes.ABOUT_US}>
                    <span>About Us</span>
                  </Link>
                  {/* <!-- sub menu start--> */}
                  {/* <ul className="main-menu__sub-list sub-list--style-2">
                                        <li><a href="about.html"><span>About</span></a></li>
                                        <li><a href="typography.html"> <span>Typography</span></a></li>
                                        <li><a href="donors.html"><span>Donors & Partners</span></a></li>
                                        <li><a href="volunteer.html"><span>Become a Volunteer</span></a></li>
                                        <li><a href="events.html"><span>Events</span></a></li>
                                        <li><a href="event-details.html"><span>Event Details</span></a></li>
                                        <li><a href="stories.html"><span>Stories</span></a></li>
                                        <li><a href="storie-details.html"><span>Story Details</span></a></li>
                                        <li><a href="blog.html"><span>Blog</span></a></li>
                                        <li><a href="blog-post.html"><span>Blog Post</span></a></li>
                                        <li><a href="gallery.html"><span>Gallery</span></a></li>
                                        <li><a href="pricing.html"><span>Pricing Plans</span></a></li>
                                        <li><a href="faq.html"><span>FAQ</span></a></li>
                                        <li><a href="404.html"><span>404 Page</span></a></li>
                                    </ul> */}
                  {/* <!-- sub menu end--> */}
                </li>
                {/* className="main-menu__item main-menu__item--has-child" */}
                {/* <li className={routes.BLOG.includes(history.pathname) && window.location.hash === `#${routes.BLOG_HASH}` ? "main-menu__item main-menu__item--active" : "main-menu__item"}><Link className="main-menu__link" to={routes.BLOG}><span>Blog</span></Link> */}
                {/* <!-- sub menu start--> */}
                {/* <ul className="main-menu__sub-list">
                                        <li><a href="causes.html"><span>Causes 1</span></a></li>
                                        <li><a href="causes_2.html"> <span>Causes 2</span></a></li>
                                        <li><a href="causes_3.html"><span>Causes 3</span></a></li>
                                        <li><a href="cause-details.html"><span>Cause Details</span></a></li>
                                    </ul> */}
                {/* <!-- sub menu end--> */}
                {/* </li> */}
                <li
                  className={
                    routes.INFRASTRUCTURE_PAGE.includes(history.pathname)
                      ? "main-menu__item main-menu__item--active"
                      : "main-menu__item"
                  }
                >
                  <Link
                    className="main-menu__link"
                    to={routes.INFRASTRUCTURE_PAGE}
                  >
                    <span>Infrastructure</span>
                  </Link>
                  {/* <!-- sub menu start--> */}
                  {/* <ul className="main-menu__sub-list">
                                        <li><a href="shop.html"><span>Catalog Page</span></a></li>
                                        <li><a href="shop-product.html"><span>Shop Product</span></a></li>
                                        <li><a href="shop-cart.html"><span>Shop Cart</span></a></li>
                                        <li><a href="shop-checkout.html"><span>Shop Checkout</span></a></li>
                                        <li><a href="shop-account.html"><span>Shop Account</span></a></li>
                                    </ul> */}
                  {/* <!-- sub menu end--> */}
                </li>
                <li
                  className={
                    routes.EVENT_GALLERY.includes(history.pathname) &&
                    window.location.hash === `#${routes.EVENT_GALLERY_HASH}`
                      ? "main-menu__item main-menu__item--active"
                      : "main-menu__item"
                  }
                >
                  <Link className="main-menu__link" to={routes.EVENT_GALLERY}>
                    <span>Event Gallery</span>
                  </Link>
                </li>
                <li
                  className={
                    history.pathname.includes(routes.SOCIAL_PROJECTS)
                      ? "main-menu__item main-menu__item--active"
                      : "main-menu__item"
                  }
                >
                  <Link className="main-menu__link" to={routes.SOCIAL_PROJECTS}>
                    <span>Social Projects</span>
                  </Link>
                  {/* <!-- sub menu start--> */}
                  {/* <ul className="main-menu__sub-list">
                                        <li><a href="alerts.html"><span>Alerts</span></a></li>
                                        <li><a href="team.html"><span>Team</span></a></li>
                                        <li><a href="testimonials.html"><span>Testimonials</span></a></li>
                                        <li><a href="accordion.html"><span>Accordion</span></a></li>
                                        <li><a href="tabs.html"><span>Tabs</span></a></li>
                                        <li><a href="pricing-plan.html"><span>Pricing Plan</span></a></li>
                                        <li><a href="counters.html"><span>Counters</span></a></li>
                                        <li><a href="icons.html"><span>Icons</span></a></li>
                                    </ul> */}
                  {/* <!-- sub menu end--> */}
                </li>
                <li
                  className={
                    history.pathname.includes(routes.CONTACT_US)
                      ? "main-menu__item main-menu__item--active"
                      : "main-menu__item"
                  }
                >
                  <Link className="main-menu__link" to={routes.CONTACT_US}>
                    <span>Contact Us</span>
                  </Link>
                </li>
                <li
                  className={
                    history.pathname.includes(routes.CONCLAVE)
                      ? "main-menu__item main-menu__item--active"
                      : "main-menu__item"
                  }
                >
                  <a
                    className="main-menu__link"
                    target={"_blank"}
                    rel="noreferrer"
                    href={routes.CONCLAVE}
                  >
                    <span>
                      Conclave <span className="new">new</span>
                    </span>
                  </a>
                </li>
              </ul>
            </nav>
            {/* <!-- main menu end--> */}
          </div>
          <div className="col-auto d-flex align-items-center">
            {/* <!-- lang select start--> */}
            {/* <ul className="lang-select">
                            <li className="lang-select__item lang-select__item--active"><span>Contact Us</span>
                                <ul className="lang-select__sub-list">
                                    <li><a href="#">French</a></li>
                                    <li><a href="#">Spanish</a></li>
                                    <li><a href="#">Deutsch</a></li>
                                    <li><a href="#">Russian</a></li>
                                </ul>
                            </li>
                        </ul> */}
            {/* <!-- lang select end--> */}
            <div
              onClick={() => {
                var dropdown = window.$(".aside-dropdown");
                dropdown.addClass("aside-dropdown--active");
              }}
              className="dropdown-trigger d-block d-sm-none"
            >
              <div className="dropdown-trigger__item"></div>
            </div>
            {JOIN_US.isFormActive === "Yes" ? (
              <a className="button button--squared" href={JOIN_US.formURL}>
                <span>Join Us</span>
              </a>
            ) : (
              <button
                data-toggle="modal"
                data-target="#joinUsModal"
                className="button button--squared naked_btn"
                type="button"
              >
                <span>Join Us</span>
              </button>
            )}
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
