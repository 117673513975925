export const routes = {
  HOME: "/home",
  ROOT: "/",
  CONTACT_US: "/contact-us",
  ABOUT_US: "/about-us",
  PRIVACY_POLICY: "/privacy-policy",
  TERMS_AND_CONDITIONS: "/terms-and-conditions",
  SOCIAL_PROJECTS: "/social-projects",
  BLOG: "/home#blog",
  INFRASTRUCTURE: "/home#infrastructure",
  EVENT_GALLERY: "/home#event-gallery",
  INFRASTRUCTURE_HASH: "infrastructure",
  BLOG_HASH: "blog",
  EVENT_GALLERY_HASH: "event-gallery",
  SOCIAL_PROJECTS_HASH: "social-projects",
  CHENNAI_FOOD_BANK: "https://www.chennaifoodbank.com/",
  EVENT_GALLERY_PAGE: "/event-gallery",
  INFRASTRUCTURE_PAGE: "/infrastructure",
  CONCLAVE: "https://conclave.ryametro.com",
  REFUND_RETURNS: "/returns-and-refunds",
};

export const socialProjectsItems = [
  {
    title: "Who runs Chennai Food Bank?",
    description:
      "Chennai Food Bank is a charity program of RYA Madras Metro Trust. It is an ISO certified organisation managed by a 14-member committee. Every two years, five members retire by rotation and new office bearers are elected. The President, Secretary, Vice President and Project Chairman of RYA Metro are additional Trustees who are elected every year. The project also has an Advisory Committee to offer counsel and promote foodbanking throughout the country. Eminent personalities, industrialists, technocrats and representatives of affiliated organisations constitute the Chennai Food Bank Advisory Committee.",
  },
  {
    title: "What does Chennai Food Bank aim to achieve?",
    description:
      "Chennai Food Bank is a charity program of RYA Madras Metro Trust. It is an ISO certified organisation managed by a 14-member committee. Every two years, five members retire by rotation and new office bearers are elected. The President, Secretary, Vice President and Project Chairman of RYA Metro are additional Trustees who are elected every year. The project also has an Advisory Committee to offer counsel and promote foodbanking throughout the country. Eminent personalities, industrialists, technocrats and representatives of affiliated organisations constitute the Chennai Food Bank Advisory Committee.",
  },
  {
    title: "How does Chennai Food Bank work?",
    description:
      "The RYA Madras Metro Trust motivates and promotes food banking across cities in India apart from regularly supporting over 100 institutions annually with regular supplies of food grains. In times of natural calamity anywhere in the country, Chennai Food Bank with the help of members, volunteers, Government organisations, partner NGO's pool their resources and contribute effectively for the cause. Members of RYA Metro, local donors contribute fresh food grains to Chennai Food Bank for distribution. Donors from across India & overseas contribute to the Chennai Food Bank activities and best quality grains are sourced for constant supply to needy institutions which house the lesser privileged.",
  },
  {
    title: "How does a company / individual become a donor?",
    description: `We invite organisations to make contribution to the community by donating goods, products and services. We welcome you to join the movement by contributing to any of our schemes.`,
    link: "https://beta.chennaifoodbank.com/schemes/",
    linkText: "View Schemes",
  },
];

export const eventGalleryItems = [
  {
    // title: "Let's Roll Public",
    // eventDate: "23 Jan'22",
    eventImage: "custom/event-img-1.jpg",
    // eventType: "entertainment",
    // eventLink:
    //   "https://photos.google.com/share/AF1QipONsFTigfbWViPjB0MP7FhroRNdfT41v6p38s2T61uVKzvmRcUl4VsASi4h3QW1eQ?key=V203UExSMm84MzhLakQ3MjJKT19hS3h5c1U5cHpn",
    // eventLinkText: "View Photo Album",
    featured: true,
  },
];

export const JOIN_US = {
  isFormActive: "No",
  formURL: "https://www.google.co.in",
};

export const SOCIAL_MEDIA_URLS = {
  FB: "https://www.facebook.com/groups/ryametro/?ref=share",
  INSTAGRAM: "https://www.instagram.com/ryametro/",
  YOUTUBE: "https://youtube.com/channel/UCnlJFcUGrmSPJ-ceEXxlQFQ",
};

export const infrastructureBannerImages = [
  "custom/infrastructure/1.jpg",
  "custom/infrastructure/2.jpg",
  "custom/infrastructure/3.jpg",
  "custom/infrastructure/4.jpg",
  "custom/infrastructure/5.jpg",
  "custom/infrastructure/6.jpg",
  "custom/infrastructure/7.jpg",
  "custom/infrastructure/8.jpg",
  "custom/infrastructure/9.jpg",
  "custom/infrastructure/10.jpg",
  "custom/infrastructure/11.jpg",
  "custom/infrastructure/12.jpg",
  "custom/infrastructure/13.jpg",
  "custom/infrastructure/14.jpg",
  "custom/infrastructure/15.jpg",
  "custom/infrastructure/16.jpg",
];
