import React from 'react'
import { routes } from '../../utility/constant'
import { baseImagePath } from '../../utility/functions'
import { Link } from 'react-router-dom'

const AboutUs = props => {
    return (
        <>
            <section className={props.isHome ? "section about-us about-us--blue" : "section about-us"} id="about">
                <div className={props.isHome ? "container" : "container about-us--blue about_us_wrapper"}>
                    <div className="row">
                        <div className="col-lg-4">
                            <div className="heading heading--primary"><span className="heading__pre-title color--mono">About Us</span>
                                <h2 className="heading__title color--white"><span>RYA Metro</span><br /> <span>Give for People</span></h2>
                            </div>
                            {/* <Link className="button button--primary d-none d-lg-inline-block" to={routes.ABOUT_US}>More About</Link> */}
                        </div>
                        <div className="col-lg-8">
                            <p><strong className="color--white">RYA Metro is a non profit organisation based out of Chennai, India. It is affiliated to the ancestor club RYA (Rajasthan Youth Association), which is over half a century old. RYA Metro has a motto of serving the community at scale, that makes India a better place. Its registered trust is called RYA Madras Metro Trust.
                            </strong></p>
                            {
                                props.isHome
                                    ? <Link className="button button--primary d-none d-lg-inline-block mt-5" to={routes.ABOUT_US}>More About</Link>
                                    : <p>RYA Metro has 150+ members from varied & diverse Rajasthani communities. RYA Metro is a young, vibrant and dynamic group that is committed to give back to the society with varied social causes every year. As much as members, their spouses too significantly contribute to make a meaningful impact. It ensures to cherish fun and fellowship to create strong bonding between its members and families as a whole. Do check out the ACTIVITIES section of this site to get a glimpse of all their projects so far. Feel free to write to us with any questions you may have at updates@ryametro.com .
                                    </p>
                            }
                            {/* <p>Sharksucker sea toad candiru rocket danio tilefish stingray deepwater stingray Sacramento splittail, Canthigaster rostrata. Midshipman dartfish Modoc sucker, yellowtail kingfish basslet. Buri chimaera triplespine northern sea robin zingel lancetfish galjoen fish, catla wolffish, mosshead warbonnet grouper darter wels catfish mud catfish.</p><a className="button button--primary d-inline-block d-lg-none margin-top-30" href="about.html">More About</a> */}
                        </div>
                    </div>
                </div>
            </section>
            {/* <section className="section icons-section no-padding-top">
                <div className="container">
                    <div className="row offset-margin">
                        <div className="col-6 col-lg-3">
                            <div className="icon-item icon-item--with-line text-left">
                                <div className="icon-item__img">
                                    <span className="icon icon-item__icon color--mono">
                                        <img src={baseImagePath('svg/donation.svg')} alt="donation" />
                                    </span>
                                </div>
                                <div className="icon-item__text">
                                    <p className="color--white">Medicine Help</p><span className="color--white">Sea chub demoiselle whalefish zebra lionfish mud cat pelican eel. Minnow snoek icefish velvet-belly</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-6 col-lg-3">
                            <div className="icon-item icon-item--with-line text-left">
                                <div className="icon-item__img">
                                    <svg className="icon icon-item__icon color--mono">
                                        <use xlinkHref="#church"></use>
                                    </svg>
                                </div>
                                <div className="icon-item__text">
                                    <p className="color--white">We Build and Create</p><span className="color--white">Midshipman dartfish Modoc sucker, yellowtail kingfish basslet. Buri chimaera triplespine northern sea </span>
                                </div>
                            </div>
                        </div>
                        <div className="col-6 col-lg-3">
                            <div className="icon-item icon-item--with-line text-left">
                                <div className="icon-item__img">
                                    <svg className="icon icon-item__icon color--mono">
                                        <use xlinkHref="#blood"></use>
                                    </svg>
                                </div>
                                <div className="icon-item__text">
                                    <p className="color--white">Water Delivery</p><span className="color--white">Sharksucker sea toad candiru rocket danio tilefish stingray deepwater stingray Sacramento splittail, </span>
                                </div>
                            </div>
                        </div>
                        <div className="col-6 col-lg-3">
                            <div className="icon-item icon-item--with-line text-left">
                                <div className="icon-item__img">
                                    <svg className="icon icon-item__icon color--mono">
                                        <use xlinkHref="#charity"></use>
                                    </svg>
                                </div>
                                <div className="icon-item__text">
                                    <p className="color--white">We Care About</p><span className="color--white">Canthigaster rostrata. Midshipman dartfish. Sharksucker sea toad candiru rocket danio tilefish stingra</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}
        </>
    )
}

export default AboutUs