import React from "react";
import AboutUs from "../AboutUs/AboutUs";
// import Blog from "../Blog/Blog";
import EventGallery from "../EventGallery/EventGallery";
// import Infrastructure from "../Infrastructure/Infrastructure";
import PromoBanners from "../PromoBanners/PromoBanners";
// import SocialProjects from "../SocialProjects/SocialProjects";

const HomePage = (props) => {
  return (
    <>
      <PromoBanners />
      <AboutUs isHome={true} />
      {/* <Blog /> */}
      {/* <Infrastructure /> */}
      <EventGallery isHome={true} />
      {/* <SocialProjects /> */}
    </>
  );
};

export default HomePage;
