import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import AboutUs from "../Components/AboutUs/AboutUs";
import ContactUs from "../Components/ContactUs/ContactUs";
import GooglePhotosEmbeded from "../Components/GooglePhotosEmbeded/GooglePhotosEmbeded";
import PrivacyPolicy from "../Components/PrivacyPolicy/PrivacyPolicy";
import ReturnRefund from "../Components/RefundsReturn/ReturnRefunds";
import ScrollToTop from "../Components/ScrollToTop/ScrollToTop";
import SocialProjects from "../Components/SocialProjects/SocialProjects";
import TermsAndConditions from "../Components/TermsAndConditions/TermsAndConditions";
import EventGalleryPage from "../Container/EventGallery/EventGallery";
import Home from "../Container/Home/Home";
import InfrastructurePage from "../Container/Infrastructure/Infrastructure";
import Layout from "../hoc/Layout/Layout";
import { routes } from "../utility/constant";

const Router = (props) => {
  return (
    <Layout>
      <ScrollToTop>
        <Routes>
          <Route
            path={routes.ROOT}
            element={<Navigate replace to={routes.HOME} />}
          />
          <Route path={routes.HOME} element={<Home />} />
          <Route path={routes.REFUND_RETURNS} element={<ReturnRefund />} />
          <Route path={routes.ABOUT_US} element={<AboutUs isHome={false} />} />
          <Route path={routes.SOCIAL_PROJECTS} element={<SocialProjects />} />
          <Route path={routes.CONTACT_US} element={<ContactUs />} />
          <Route
            path={routes.INFRASTRUCTURE_PAGE}
            element={<InfrastructurePage />}
          />
          <Route
            path={routes.EVENT_GALLERY_PAGE}
            element={<GooglePhotosEmbeded />}
          />
          <Route path={routes.PRIVACY_POLICY} element={<PrivacyPolicy />} />
          <Route
            path={routes.TERMS_AND_CONDITIONS}
            element={<TermsAndConditions />}
          />
          <Route path={"*"} element={<Navigate replace to={routes.HOME} />} />
        </Routes>
      </ScrollToTop>
    </Layout>
  );
};

export default Router;
