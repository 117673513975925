import React from "react";

const PrivacyPolicy = (props) => {
  return (
    <section className="section events" id="privacy-policy">
      <div className="container">
        <div className="row margin-bottom">
          <div className="col-12">
            <div className="heading heading--primary heading--center">
              {/* <span className="heading__pre-title">Events</span> */}
              <h2 className="heading__title">
                <span>RYA</span> <span>Privacy Policy</span>
              </h2>
            </div>
          </div>
        </div>
        <p className="no-margin-bottom">
          Table Of Contents
          <ul>
            <li>Who we are and how to contact us</li>
            <li>Categories of personal data collected about you and others</li>
            <li>
              How your personal data is used and the legal basis for its
              processing
            </li>
            <li>Data Retention</li>
            <li>How your personal data is disclosed</li>
            <li>Third-Party Websites</li>
            <li>Your Data Protection Rights</li>
            <li>Communities and Forums Offered on our Site</li>
            <li>Children’s Privacy</li>
            <li>Changes to This Policy</li>
          </ul>
          <b>Who we are and how to contact us</b> <br /> RYA Madras Metro Trust
          controls your personal data together with its related affiliates RYA
          METRO (“RYA Metro”, “our” or “we”). <br />
          This Data Protection and Privacy Policy (“Policy”) is intended to
          explain to you, as a user of Rya Metro or our services, what personal
          data we collect about you, how we use that data, with whom it may be
          shared, and your options about such uses and disclosures. <br />
          If you have any questions about this Policy or our personal data
          processing practices, or if you would like to request information
          about our disclosure of personal data to third parties for their
          direct marketing purposes, please contact us by email, telephone, or
          mail as follows: <br />
          RYA Madras Metro Trust 12, Saravana St, Parthasarathi Puram, T. Nagar,
          Chennai, Tamil Nadu 600017 Phone - 044 2431 2096 updates@ryametro.com
          <br />
          RYA Metro’s Data Protection Officer can be contacted at the above
          address or phone number, or by email at updates@ryametro.com <br />
          We encourage you to read this Policy carefully when using our Site and
          services or transacting business with us. Site includes ryametro.com,
          related websites, SMS, APIs, email notifications, applications (web
          and/or mobile), buttons, widgets, ads, commerce services (“Site”), and
          any other related services that can be accessed via our website or
          that link to this Policy, and any information, text, links, graphics,
          photos, videos, or other materials or arrangements of materials
          uploaded, downloaded or appearing on the Site (collectively referred
          to as “Content”). <br />
          <br />
          <b>Categories of personal data collected about you and others</b>
          <br /> A. General. Personal data refers to any information about an
          identified or identifiable individual. When you engage in certain
          activities on our Site, such as purchasing a product, submitting
          content and/or posting content on any of our blogs, or sending us
          feedback, we may ask you to provide certain information about
          yourself. Examples of personal data may include your first and last
          name, email address, mailing address, telephone numbers, age group,
          gender and other identifying information. When setting up your profile
          on our Site, you may be asked to provide an e-mail address, phone
          number, and address. <br />
          We gather certain information about you based upon where you visit on
          our Site, and what other sites may have directed you to us. This
          information, which is collected in a variety of different ways, is
          compiled and analyzed on both a personal and an aggregated basis. This
          technology enables us to determine (i) your IP Address of your
          computer and its geographic location from installing the applications
          onto your computer or mobile device; (ii) your search terms or
          interactive behavior via our website; (iii) your purchasing behavior;
          (iv) source of the information sought by you during your search; and
          (v) the age of your results data (collectively, “Shared Information”).
          <br /> B. Other Information You Provide. We may also collect and store
          any personal data you enter on our Site or provide to us in some other
          manner, including personal data that may be contained in any comment
          you post to our Site. This includes your images (both still and
          moving). <br />
          C. Information About Others. We may also collect and store personal
          data about other people provided to us by users, potentially including
          their businesses, contact details including email addresses and/or IP
          addresses. This data is used in the legitimate interests of providing
          information to potential members, and for the provision of certain
          other information related to the potential purchase of goods or
          services from Rya Metro.. <br />
          D. Automatic Collection of Data. Information concerning your computer
          hardware and software is automatically collected and may be shared
          with other Rya Metro or affiliated websites. This information may
          include: your IP address, browser type and language, domain names,
          access times, referring website addresses and the content of any
          undeleted cookies that your browser previously accepted from us (see
          “Cookies” below). <br />
          E. Cookies. When you visit our Site, if you provide opt-in consent, we
          may use one or more “Cookies” to make our Site easier for you to use
          and to personalize your online experience. Through the use of a
          cookie, we also may automatically collect information about your
          online activity on our Site, such as the web pages you visit, the
          links you click, and the amount of time you spend on the Site. You can
          withhold or withdraw consent, but if you choose to decline cookies,
          you may not be able to sign in or use some of the features offered on
          our Site. <br />
          F. Other Technologies. We may use the ever-evolving Internet
          technology, such as web beacons and other similar technologies, to
          track your use of our Site. We also may include web beacons in company
          e-mail messages, newsletters, or notifications to determine whether
          messages have been opened and/or acted upon. This enables us to
          customize the services we offer our Site visitors to deliver targeted
          advertisements and to measure the overall effectiveness of our online
          advertising, content, programming or other activities. <br />
          G. Information From Other Sources. We sometimes supplement the
          information that we receive that has been outlined in this Policy with
          information from other sources and companies. Such outside information
          includes updated delivery and address information from carriers or
          third parties, which enables us to correct our records and deliver,
          for example, future communications more easily; account information,
          purchase or redemption information, and page-view information from
          some merchants with which we operate co-branded businesses or for
          which we provide technical, fulfilment, advertising, or other
          services; search term and search result information from some searches
          conducted through the Web search features; search results and links,
          including paid listings (such as Sponsored Links from Google); and
          credit history information from credit bureaus, which we use to help
          prevent and detect fraud and to offer certain credit or financial
          services to some customers. <br /> <br />
          <b>
            How your personal data is used and the legal basis for its
            processing
          </b>
          <br />
          We primarily use your personal data to provide support or service
          offerings to you. <br />
          A. Contractual Necessity. On the basis of contractual necessity, we
          process the personal data of members and membership applicants to:
          <ul>
            <li>manage your account and provide you with customer support;</li>
            <li>enforce our terms and conditions;</li>
            <li>manage our business;</li>
            <li>
              enable and leverage applications such as RYA METRO app to provide
              user functionality;
            </li>
            <li>
              process payment for any purchases, subscriptions or sales made on
              our Site, and to protect you and ourselves against, or identify,
              possible fraudulent transactions, and otherwise as needed to
              manage our business.
            </li>
          </ul>
          <br />
          B. Legitimate Interest. In our legitimate interests in the maintenance
          and development of our business, we may process your data to:
          <ul>
            <li>
              perform research and analysis about your use of, or interest in,
              our products, services, or content, or products, services or
              content offered by others; this information may be used, by way of
              example only, to help us understand the demographics of potential
              members, identify chapters with openings for certain professions,
              inform future marketing campaigns, and judge the success of
              marketing strategies;
            </li>
            <li>
              cross market your services/products with our services and our
              affiliates’ services;
            </li>
            <li>
              verify your eligibility and deliver online and/or offline in
              connection with contests and sweepstakes;
            </li>
            <li>
              protect and defend the rights or property of RYA Metro or one of
              RYA Metro affiliates.
            </li>
          </ul>
          <br />
          C. Consent. You may consent to us:
          <ul>
            <li>
              providing your email address and other information to our business
              partners for offers to you of products, services, promotions,
              contests and/or sweepstakes;
            </li>
            <li>sending you email notifications and/or newsletters.</li>
            <li>
              Creating and operating cookies on your devices when you visit our
              websites.
            </li>
          </ul>
          You have the right to withdraw your consent at any time by emailing us
          at updates@ryametro.com.
          <br />
          D. Legal Necessity. When legally necessary, we may also process your
          personal data:
          <ul>
            <li>
              to establish or exercise our legal rights, or to defend against
              legal claims;
            </li>
            <li>
              in the vital interests of staff, members and users of Rya Metro,
              or the public, to protect their personal safety.
            </li>
          </ul>
          <br />
          <br />
          <b>Data Retention</b>
          Your data will only be retained for as long as necessary to fulfil the
          purposes for which it was obtained and/or to meet any applicable legal
          obligations. <br /> <br /> <b>How your personal data is disclosed</b>
          <br /> We may share your personal data with: <br />
          A. Authorized Service Providers. Authorized service providers
          performing services on our behalf, e.g., processing credit card
          payments, delivering products and services, providing customer service
          and marketing assistance, performing business and sales analysis,
          supporting our Site’s functionality, and supporting other features and
          promotions offered through our Site. <br />
          B. Business partners. From time to time, we may offer products,
          services, promotions, contests and/or sweepstakes on our Site and
          share personal data with the businesses with which we partner to
          provide these offers. <br />
          C. Other Situations. We may also disclose your information:
          <ul>
            <li>
              under a legal obligation, including but not limited to, subpoena
              or court order;
            </li>
            <li>
              under legal authority to respond to requests for cooperation from
              a law enforcement or other government agency (including civil and
              criminal matters);
            </li>
            <li>
              to prevent illegal activity, to prevent imminent bodily harm, or
              to protect ourselves and you from people violating our Terms of
              Service. This may include sharing personal data with, for example,
              other companies, lawyers, courts or other government entities
              where applicable;
            </li>
          </ul>
          Any third parties to whom we may disclose personal data may have their
          own data protection and privacy notices/policies, which we encourage
          you to view on the websites of those third parties. <br /> <br />{" "}
          <b>Third-Party Websites</b>
          There are a number of places on our Site or through our services where
          you may click on a link to access other websites that do not operate
          under this Policy. For example, if you click on an advertisement or a
          search result on our Site, you may be taken to a website that we do
          not control. These third-party websites may independently solicit and
          collect personal data from you and, in some instances, provide us with
          information about your activities on those websites. We recommend that
          you consult the data protection and privacy notices/policies of all
          third-party websites you visit. <br /> <br />{" "}
          <b>Your Data Protection Rights</b> <br />
          Under GDPR, you have the following rights:
          <ul>
            <li>
              You have the right to be provided on request with a copy of your
              personal data.
            </li>
            <li>
              You have the right to rectification of inaccurate data we may have
              recorded about you.
            </li>
            <li>
              You have the right to the erasure of your data (“Right to be
              Forgotten”) which we no longer have justification for recording.
            </li>
            <li>
              You have the right to object to processing of your data which is
              being done on the basis of our legitimate interests.
            </li>
            <li>
              You have the right to refuse to be contacted for direct marketing
              purposes.
            </li>
            <li>
              You have the right to restrict the processing of your personal
              data when:
              <ul>
                <li>the basis for its processing is in dispute;</li>
                <li>its accuracy is in dispute;</li>
                <li>
                  we cannot establish a lawful basis for its processing, but you
                  do not wish it to be erased;
                </li>
                <li>
                  we no longer need your data, but you need it for the
                  establishment, exercise, or defense of legal claims;
                </li>
                <li>
                  you object, where applicable, to our processing your data on
                  the basis of our legitimate interests, pending verification of
                  whether our interests override.
                </li>
              </ul>
            </li>
            <li>
              You have the right to be provided in a structured, commonly used
              and machine-readable format with a copy of your data which we
              process on the basis of your consent or contractual necessity, or
              to have it transferred directly to another data controller (“Data
              Portability”).
            </li>
          </ul>
          <br />
          If you are not satisfied with our responses or are otherwise concerned
          with how we process your personal data, you also have the right to
          make a complaint to our supervisory authority, Ireland’s Data
          Protection Commission (www.dataprotection.ie). <br />
          <br /> <b>Communities and Forums Offered on our Site</b> <br />
          We may provide areas on our Site where you can post information about
          yourself, including your name and email address, communicate with
          others; upload content; and post comments. Such postings are governed
          by our Terms of Service. Also, whenever you voluntarily disclose
          personal data on our Site, that data will be available and can be
          collected and used by others who have authorized access to your
          content. <br />
          We encourage you to exercise discretion and caution with respect to
          your personal data. Once you have posted data, you may not be able to
          edit or delete such data. <br /> Any personal data that you disclose
          through our Site, or other online forums hosted on our Site, may be
          collected and used by others. We recommend caution when giving out
          personal data to others in these online forums. <br />
          <br /> <b>Children’s Privacy</b> <br /> Our Site is a general audience
          site not intended for use by children, and we do not knowingly collect
          personal data from children. You may access our Site only if you are
          of legal age to lawfully form a binding contract. <br />
          <br /> <b>Changes to This Policy</b> <br /> We will occasionally
          update this Policy to reflect changes in our practices and services.
          When we post changes, we will revise the “Last Updated” date at the
          top of the Policy. If we make any material changes in the way we
          collect, use, and/or share your personal data, we will notify you by
          email to the email address you most recently provided us in your
          account, profile or registration, if we have such an email address,
          and/or by prominently posting notice of the changes on our Site. We
          recommend that you check our Site from time to time to inform yourself
          of any changes in this Policy. ©2022 RYA Madras Metro Trust. All
          rights reserved.
        </p>
      </div>
    </section>
  );
};

export default PrivacyPolicy;
