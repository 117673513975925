import './App.css';
import './assets/css/styles.min.css';
import './assets/slick/slick.css'
import './assets/css/custom.css'
import AppRouter from './router/index'

const App = () => {
  return (
    <AppRouter />
  );
}

export default App;
