import React from "react";
import JoinUsPopup from "../../Components/JoinUsPopup";
import Footer from "./Footer/Footer";
import Header from "./Header/Header";
import MobileNav from "./MobileNav/MobileNav";
import SideNav from "./SideNav/SideNav";

const Layout = props => {
    return (
        <div className="page-wrapper">
            <MobileNav />
            <SideNav />
            <Header />
            <main className="main">
                {props.children}
            </main>
            <JoinUsPopup />
            <Footer />
        </div>
    )
}

export default Layout