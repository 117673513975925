import React from "react";
import { SOCIAL_MEDIA_URLS } from "../../utility/constant";
import { baseImagePath } from "../../utility/functions";

const ContactUs = (props) => {
  return (
    <>
      <section className="promo-primary">
        <picture>
          <source
            srcSet={baseImagePath("custom/contact-us-bg.jpg")}
            media="(min-width: 992px)"
          />
          <img
            className="img--bg"
            src={baseImagePath("custom/contact-us-bg.jpg")}
            alt="img"
          />
        </picture>
        <div className="promo-primary__description">
          {" "}
          <span>RYA METRO</span>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-auto">
              <div className="align-container">
                <div className="align-container__item">
                  {/* <span className="promo-primary__pre-title">RYA</span> */}
                  <h1 className="promo-primary__title">
                    <span>Contact Us</span>
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section contacts">
        <div className="container">
          <div className="row offset-margin">
            <div className="col-sm-6 col-lg-3">
              <div className="icon-item">
                <div className="icon-item__img">
                  <span className="icon icon-item__icon">
                    <img
                      src={baseImagePath("svg/020-location.svg")}
                      alt="location"
                    />
                  </span>
                </div>
                <div className="icon-item__text">
                  <p>
                    Adress: 12, Saravana St, Parthasarathi Puram, T. Nagar,
                    Chennai, Tamil Nadu 600017
                  </p>
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-lg-3">
              <div className="icon-item">
                <div className="icon-item__img">
                  <span className="icon icon-item__icon">
                    <img
                      src={baseImagePath("svg/phone-call.svg")}
                      alt="phone"
                    />
                  </span>
                </div>
                <div className="icon-item__text">
                  <p>
                    Phone:{" "}
                    <a className="icon-item__link" href="tel:+914424312096">
                      +91 44 2431 2096
                    </a>
                  </p>
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-lg-3">
              <div className="icon-item">
                <div className="icon-item__img">
                  <span className="icon icon-item__icon">
                    <img
                      src={baseImagePath("svg/envelope.svg")}
                      alt="envelope"
                    />
                  </span>
                </div>
                <div className="icon-item__text">
                  <p>
                    Email:{" "}
                    <a
                      className="icon-item__link"
                      href="mailto:updates@ryametro.com"
                    >
                      updates@ryametro.com
                    </a>
                  </p>
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-lg-3">
              <div className="icon-item">
                <div className="icon-item__img">
                  <span className="icon icon-item__icon">
                    <img src={baseImagePath("svg/share.svg")} alt="share" />
                  </span>
                </div>
                <div className="icon-item__text">
                  {/* <!-- socials start--> */}
                  <ul className="socials">
                    <li className="socials__item">
                      <a
                        className="socials__link"
                        href={SOCIAL_MEDIA_URLS.INSTAGRAM}
                        target={"_blank"}
                        rel="noreferrer"
                      >
                        <i className="fa fa-instagram" aria-hidden="true"></i>
                      </a>
                    </li>
                    {/* <li className="socials__item"><a className="socials__link" href="#"><i className="fa fa-google-plus" aria-hidden="true"></i></a></li> */}
                    <li className="socials__item">
                      <a
                        className="socials__link socials__link--active"
                        href={SOCIAL_MEDIA_URLS.YOUTUBE}
                        target={"_blank"}
                        rel="noreferrer"
                      >
                        <i className="fa fa-youtube" aria-hidden="true"></i>
                      </a>
                    </li>
                    <li className="socials__item">
                      <a
                        className="socials__link"
                        target={"_blank"}
                        rel="noreferrer"
                        href={SOCIAL_MEDIA_URLS.FB}
                      >
                        <i className="fa fa-facebook" aria-hidden="true"></i>
                      </a>
                    </li>
                  </ul>
                  {/* <!-- socials end--> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section contacts no-padding-top">
        <div className="contacts-wrapper">
          <div className="container">
            <div className="row justify-content-end">
              <div className="col-xl-6">
                <form
                  className="form message-form"
                  method="POST"
                  action="javascript:void(0);"
                >
                  <h6 className="form__title">Send Message</h6>
                  <span className="form__text">
                    * The following info is required
                  </span>
                  <div className="row">
                    <div className="col-lg-6">
                      <input
                        className="form__field"
                        type="text"
                        name="first-name"
                        placeholder="First Name *"
                        required="required"
                      />
                    </div>
                    <div className="col-lg-6">
                      <input
                        className="form__field"
                        type="text"
                        name="last-name"
                        placeholder="Last Name *"
                        required="required"
                      />
                    </div>
                    <div className="col-lg-6">
                      <input
                        className="form__field"
                        type="email"
                        name="email"
                        placeholder="Email *"
                        required="required"
                      />
                    </div>
                    <div className="col-lg-6">
                      <input
                        className="form__field"
                        type="tel"
                        name="phone-number"
                        placeholder="Phone"
                      />
                    </div>
                    <div className="col-12">
                      <textarea
                        className="form__message form__field"
                        name="message"
                        placeholder="Message"
                      ></textarea>
                    </div>
                    <div className="col-12">
                      <button className="form__submit" type="submit">
                        Send Message
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="contacts-wrapper__map" id="map">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3886.964923853944!2d80.24044181445304!3d13.037904690812407!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a5267acc9586551%3A0xea9d0c5f720133f7!2sRYA%20Metro%20Hall!5e0!3m2!1sen!2sin!4v1650878970654!5m2!1sen!2sin"
              width="600"
              height="450"
              title="rya-address"
              style={{ border: "0" }}
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </div>
      </section>
      {/* <section className="bottom-background" style={{ backgroundImage: `url(${baseImagePath('theme/bottom-bg.png')})` }}></section> */}
    </>
  );
};

export default ContactUs;
