import React from "react";

const MobileNav = props => {
    return (
        <div className="mobile-nav">
            <div className="mobile-nav__inner">
                <div className="mobile-nav__item">
                    <nav className="menu-holder">
                        <ul className="mobile-menu">
                            <li className="mobile-menu__item"><a className="mobile-menu__link" href="#">item</a></li>
                            <li className="mobile-menu__item"><a className="mobile-menu__link" href="#">item</a></li>
                            <li className="mobile-menu__item"><a className="mobile-menu__link" href="#">item</a></li>
                            <li className="mobile-menu__item"><a className="mobile-menu__link" href="#">item</a></li>
                        </ul>
                    </nav>
                </div>
            </div>
        </div>
    )
}

export default MobileNav