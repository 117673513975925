import React from "react";
import { eventGalleryItems, routes } from "../../utility/constant";
import { baseImagePath } from "../../utility/functions";
import { Link } from "react-router-dom";
import './EventGallery.css'

const EventGallery = (props) => {
  let events = eventGalleryItems;
  if (props.isHome) {
    events = eventGalleryItems.filter((i) => i.featured);
  }
  return (
    <section
      className="section no-padding-top no-padding-bottom"
      id={routes.EVENT_GALLERY_HASH}
    >
      <div className="row no-gutters">
        <div className="col-lg-6 col-xl-8 projects-masonry__item projects-masonry__item--height-2 projects-masonry__item--primary projects-masonry__item--heading">
          <div className="projects-masonry__img">
            <div className="heading heading--primary">
              <span className="heading__pre-title">RYA Metro</span>
              <h2 className="heading__title">
                <span>Event</span> <span>Gallery</span>
              </h2>
              <p>
                Rya Metro has conducted various kinds of event like social,
                educational, entertainment programs.
              </p>
              {props.isHome ? (
                <Link
                  className="button button--primary d-none d-lg-inline-block mt-5"
                  to={routes.EVENT_GALLERY_PAGE}
                >
                  View Photo Album
                </Link>
              ) : null}
            </div>
          </div>
        </div>
        {events.map((item, index) => {
          return (
            <div
              key={index}
              className="col-lg-6 col-xl-4 projects-masonry__item projects-masonry__item--height-2 projects-masonry__item--primary"
            >
              <div className="projects-masonry__img">
                <img
                  className="img--bg"
                  src={
                    item.eventImage &&
                    (item.eventImage.includes("http") ||
                      item.eventImage.includes("https") ||
                      item.eventImage.includes("www"))
                      ? item.eventImage
                      : baseImagePath(item.eventImage)
                  }
                  alt="img"
                />
                <div className="projects-masonry__inner">
                  {item.eventType ? (
                    <span className="projects-masonry__badge">
                      {item.eventType}
                    </span>
                  ) : null}
                  {item.title ? (
                    <h3
                      style={{ color: "#c1f7d5" }}
                      className="projects-masonry__title"
                    >
                      {" "}
                      {item.title}
                    </h3>
                  ) : null}
                  {item.description ? (
                    <p>
                      Murray cod clownfish American sole rockfish dojo loach
                      gulper, trout-perch footballfish, pelican eel. Spinefoot
                      coelacanth eagle ray{" "}
                    </p>
                  ) : null}
                  {item.eventDate ? (
                    <div className="projects-masonry__details-holder">
                      {/* <div className="projects-masonry__details-item"><span>Goal: </span><span>25 000$</span></div> */}
                      <div className="projects-masonry__details-item">
                        <span>Date: </span>
                        <span>{item.eventDate}</span>
                      </div>
                    </div>
                  ) : null}
                  {item.eventLink && item.eventLink ? (
                    <a
                      className="button button--filled mt-5"
                      target={"_blank"}
                      rel="noreferrer"
                      href={item.eventLink}
                    >
                      {item.eventLinkText}
                    </a>
                  ) : null}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </section>
  );
};

export default EventGallery;
