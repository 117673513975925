import React, { useEffect } from "react";
import { JOIN_US, routes, SOCIAL_MEDIA_URLS } from "../../../utility/constant";
import { Link } from "react-router-dom";

const SideNav = (props) => {
  useEffect(() => {
    var dropdown = window.$(".aside-dropdown");
    if (!dropdown.length) return;
    window.$(document).on("click", function (event) {
      if (
        window
          .$(event.target)
          .closest(".dropdown-trigger, .aside-dropdown__inner").length
      )
        return;
      dropdown.removeClass("aside-dropdown--active");
      event.stopPropagation();
    });
    setTimeout(() => {
      var i = window.$(".aside-dropdown");
      if (i.length) {
        var e = window.$(".dropdown-trigger"),
          s = window.$(".aside-dropdown__close"),
          h = window.$(".aside-menu__link"),
          k = window.$(".aside-menu__item"),
          t = window.$(".aside-dropdown .main-menu__link--scroll");

        e.on("click", () => {
          i.addClass("aside-dropdown--active");
        });
        s.on("click", function () {
          i.removeClass("aside-dropdown--active");
        });
        t.on("click", function () {
          i.removeClass("aside-dropdown--active");
        });
        h.on("click", function () {
          i.removeClass("aside-dropdown--active");
        });
        k.on("click", function () {
          i.removeClass("aside-dropdown--active");
        });
        window.$(document).on("click", (e) => {
          if (
            !window
              .$(e.target)
              .closest(".dropdown-trigger, .aside-dropdown__inner").length
          ) {
            i.removeClass("aside-dropdown--active");
            e.stopPropagation();
          }
        });
      }
    }, 1000);
  }, []);
  return (
    <div className="aside-dropdown">
      <div className="aside-dropdown__inner">
        <span
          onClick={() => {
            var dropdown = window.$(".aside-dropdown");
            dropdown.removeClass("aside-dropdown--active");
          }}
          className="aside-dropdown__close"
        >
          <i className="fa fa-close" />
        </span>
        <div className="aside-dropdown__item d-block">
          <ul className="aside-menu">
            <li className="aside-menu__item">
              <Link className="aside-menu__link" to={routes.HOME}>
                <span>Home</span>
              </Link>
            </li>
            <li className="aside-menu__item">
              <Link className="aside-menu__link" to={routes.ABOUT_US}>
                <span>About Us</span>
              </Link>
            </li>
            {/* <li className="aside-menu__item">
              <Link className="aside-menu__link" to={routes.BLOG}>
                <span>Blog</span>
              </Link>
            </li> */}
            <li className="aside-menu__item">
              <Link
                className="aside-menu__link"
                to={routes.INFRASTRUCTURE_PAGE}
              >
                <span>Infrastructure</span>
              </Link>
            </li>
            <li className="aside-menu__item">
              <Link className="aside-menu__link" to={routes.EVENT_GALLERY}>
                <span>Event Gallery</span>
              </Link>
            </li>
            <li className="aside-menu__item">
              <Link className="aside-menu__link" to={routes.SOCIAL_PROJECTS}>
                <span>Social Projects</span>
              </Link>
            </li>
            {/* <li className="aside-menu__item aside-menu__item--has-child">
              <a className="aside-menu__link" href="javascript:void(0);">
                <span>Shop</span>
              </a>
              <ul className="aside-menu__sub-list">
                <li>
                  <a href="shop.html">
                    <span>Catalog Page</span>
                  </a>
                </li>
                <li>
                  <a href="shop-product.html">
                    <span>Shop Product</span>
                  </a>
                </li>
                <li>
                  <a href="shop-cart.html">
                    <span>Shop Cart</span>
                  </a>
                </li>
                <li>
                  <a href="shop-checkout.html">
                    <span>Shop Checkout</span>
                  </a>
                </li>
                <li>
                  <a href="shop-account.html">
                    <span>Shop Account</span>
                  </a>
                </li>
              </ul>
            </li> */}
            <li className="aside-menu__item">
              <Link className="aside-menu__link" to={routes.CONTACT_US}>
                <span>Contact Us</span>
              </Link>
            </li>
            <li className="aside-menu__item">
              <a
                className="aside-menu__link"
                target={"_blank"}
                rel="noreferrer"
                href={routes.CONCLAVE}
              >
                <span>
                  Conclave <span className="new-tag-mobile">new</span>
                </span>
              </a>
            </li>
          </ul>
        </div>
        <div className="aside-dropdown__item">
          {/* <!-- aside menu start--> */}
          {/* <ul className="aside-menu">
            <li className="aside-menu__item">
              <a className="aside-menu__link" href="#">
                Documents
              </a>
            </li>
            <li className="aside-menu__item">
              <a className="aside-menu__link" href="#">
                Information
              </a>
            </li>
            <li className="aside-menu__item">
              <a className="aside-menu__link" href="#">
                Additional Pages
              </a>
            </li>
            <li className="aside-menu__item">
              <a className="aside-menu__link" href="#">
                Elements
              </a>
            </li>
            <li className="aside-menu__item">
              <a className="aside-menu__link" href="#">
                Contacts
              </a>
            </li>
          </ul> */}
          {/* <!-- aside menu end--> */}
          <div className="aside-inner">
            <span className="aside-inner__title">Email</span>
            <a className="aside-inner__link" href="mailto:updates@ryametro.com">
              updates@ryametro.com
            </a>
          </div>
          <div className="aside-inner">
            <span className="aside-inner__title">Phone number</span>
            <a className="aside-inner__link" href="tel:+914424312096">
              +91 44 2431 2096
            </a>
            {/* <a className="aside-inner__link" href="tel:+18009756511">
              + 1800 - 975 65 11
            </a> */}
          </div>
          <ul className="aside-socials">
            <li className="aside-socials__item">
              <a
                className="aside-socials__link"
                target={"_blank"}
                rel="noreferrer"
                href={SOCIAL_MEDIA_URLS.INSTAGRAM}
              >
                <i className="fa fa-instagram" aria-hidden="true"></i>
              </a>
            </li>
            {/* <li className="aside-socials__item"><a className="aside-socials__link" href="#"><i className="fa fa-google-plus" aria-hidden="true"></i></a></li> */}
            <li className="aside-socials__item">
              <a
                className="aside-socials__link aside-socials__link--active"
                target={"_blank"}
                rel="noreferrer"
                href={SOCIAL_MEDIA_URLS.YOUTUBE}
              >
                <i className="fa fa-youtube" aria-hidden="true"></i>
              </a>
            </li>
            <li className="aside-socials__item">
              <a
                className="aside-socials__link"
                target={"_blank"}
                rel="noreferrer"
                href={SOCIAL_MEDIA_URLS.FB}
              >
                <i className="fa fa-facebook" aria-hidden="true"></i>
              </a>
            </li>
          </ul>
        </div>
        <div className="aside-dropdown__item">
          {JOIN_US.isFormActive === "Yes" ? (
            <a className="button button--squared" href={JOIN_US.formURL}>
              <span>Join Us</span>
            </a>
          ) : (
            <button
              data-toggle="modal"
              data-target="#joinUsModal"
              className="button button--squared naked_btn"
              type="button"
            >
              <span>Join Us</span>
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default SideNav;
