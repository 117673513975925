import React from "react";
import { SOCIAL_MEDIA_URLS } from "../../utility/constant";
import { baseImagePath } from "../../utility/functions";

const PromoBanners = (props) => {
  return (
    <section className="promo">
      <div
        className="promo-slider"
        onLoad={() => {
          var promoSlider = window.$(".promo-slider");

          if (!promoSlider.length) return;
          var status = window.$(".promo-slider__count");
          var prev = window.$(".slider__nav--promo .slider__prev");
          var next = window.$(".slider__nav--promo .slider__next");

          promoSlider.on(
            "init afterChange",
            function (event, slick, currentSlide, nextSlide) {
              var i = (currentSlide ? currentSlide : 0) + 1;
              if (slick.slideCount) {
                status.text(`${i} / ${slick.slideCount}`);
              } else {
                status.text(`${i} / 3`);
              }
            }
          );

          promoSlider.slick({
            fade: true,

            adaptiveHeight: true,
            infinite: true,
            speed: 1200,
            prevArrow: prev,
            nextArrow: next,
          });
        }}
      >
        <div className="promo-slider__item promo-slider__item--style-1">
          <picture>
            <source
              srcSet={baseImagePath("custom/banner.jpeg")}
              media="(min-width: 992px)"
            />
            <img
              className="img--bg"
              src={baseImagePath("custom/banner.jpeg")}
              alt="img"
            />
          </picture>
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="align-container">
                  {/* <div className="align-container__item">
                    <div className="promo-slider__wrapper-1">
                      <h2 className="promo-slider__title">
                        <span>Some people need help and we give it!</span>
                      </h2>
                    </div>
                    <div className="promo-slider__wrapper-2">
                      <p className="promo-slider__subtitle">
                        Gray eel-catfish longnose whiptail catfish smalleye
                        squaretail queen danio unicorn fish shortnose greeneye
                        fusilier fish silver carp nibbler sharksucker tench
                        lookdown catfish
                      </p>
                    </div>
                    <div className="promo-slider__wrapper-3">
                      <a
                        className="button promo-slider__button button--primary"
                        href="#"
                      >
                        Discover
                      </a>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="promo-slider__item promo-slider__item--style-2">
          <picture>
            <source
              srcSet={baseImagePath("theme/promo_2.jpg")}
              media="(min-width: 992px)"
            />
            <img
              className="img--bg"
              src={baseImagePath("theme/promo_2.jpg")}
              alt="img"
            />
          </picture>
          <div className="container">
            <div className="row">
              <div className="col-lg-8 col-xl-7">
                <div className="align-container">
                  <div className="align-container__item">
                    <div className="promo-slider__wrapper-1">
                      <h2 className="promo-slider__title">
                        <span>Our Helping</span>
                        <br />
                        <span>around the world.</span>
                      </h2>
                    </div>
                    <div className="promo-slider__wrapper-2">
                      <p className="promo-slider__subtitle">
                        Gray eel-catfish longnose whiptail catfish smalleye
                        squaretail queen danio unicorn fish shortnose greeneye
                        fusilier fish silver carp nibbler sharksucker tench
                        lookdown catfish
                      </p>
                    </div>
                    <div className="promo-slider__wrapper-3">
                      <a
                        className="button promo-slider__button button--primary"
                        href="#"
                      >
                        Discover
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        {/* <div className="promo-slider__item promo-slider__item--style-3">
          <picture>
            <source
              srcSet={baseImagePath("theme/promo_3.jpg")}
              media="(min-width: 992px)"
            />
            <img
              className="img--bg"
              src={baseImagePath("theme/promo_3.jpg")}
              alt="img"
            />
          </picture>
          <div className="container">
            <div className="row">
              <div className="col-lg-10 offset-lg-1 col-xl-8 offset-xl-2">
                <div className="align-container">
                  <div className="align-container__item">
                    <div className="promo-slider__wrapper-1">
                      <h2 className="promo-slider__title">
                        <span>Helpo Volounteers</span>
                        <br />
                        <span>Around the world.</span>
                      </h2>
                    </div>
                    <div className="promo-slider__wrapper-2">
                      <p className="promo-slider__subtitle">
                        Gray eel-catfish longnose whiptail catfish smalleye
                        squaretail queen danio unicorn fish shortnose greeneye
                        fusilier fish silver carp nibbler sharksucker tench
                        lookdown catfish
                      </p>
                    </div>
                    <div className="promo-slider__wrapper-3">
                      <a
                        className="button promo-slider__button button--primary"
                        href="#"
                      >
                        Discover
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
      </div>
      <div className="promo-pannel__video">
        {/* <img
          className="img--bg"
          src={baseImagePath("custom/video-thumbnail.webp")}
          alt="alt_image"
        /> */}
        {/* <a className="video-trigger" href="https://youtu.be/I19KJ-FbhfE">
          <span>Watch our video</span>
          <i className="fa fa-play" aria-hidden="true"></i>
        </a> */}
        <iframe
          className="video-trigger"
          src="https://www.youtube.com/embed/I19KJ-FbhfE"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </div>
      {/* <!-- promo pannel start--> */}
      <div className="promo-pannel">
        <div className="promo-pannel__phones">
          <p className="promo-pannel__title">Phone number</p>
          <a className="promo-pannel__link" href="tel:+180012345678">
            +91 44 2431 2096
          </a>
          {/* <a className="promo-pannel__link" href="tel:+18009756511">+ 1800 - 975 65 11</a> */}
        </div>
        <div className="promo-pannel__email">
          <p className="promo-pannel__title">Email</p>
          <a className="promo-pannel__link" href="mailto:updates@ryametro.com">
            updates@ryametro.com
          </a>
        </div>
        <div className="promo-pannel__socials">
          <p className="promo-pannel__title">Socials</p>
          <ul className="promo-socials">
            <li className="promo-socials__item">
              <a
                className="promo-socials__link"
                target={"_blank"}
                rel="noreferrer"
                href={SOCIAL_MEDIA_URLS.INSTAGRAM}
              >
                <i className="fa fa-instagram" aria-hidden="true"></i>
              </a>
            </li>
            {/* <li className="promo-socials__item"><a className="promo-socials__link" href="#"><i className="fa fa-google-plus" aria-hidden="true"></i></a></li> */}
            <li className="promo-socials__item">
              <a
                className="promo-socials__link"
                target={"_blank"}
                rel="noreferrer"
                href={SOCIAL_MEDIA_URLS.YOUTUBE}
              >
                <i className="fa fa-youtube" aria-hidden="true"></i>
              </a>
            </li>
            <li className="promo-socials__item">
              <a
                className="promo-socials__link"
                target={"_blank"}
                rel="noreferrer"
                href={SOCIAL_MEDIA_URLS.FB}
              >
                <i className="fa fa-facebook" aria-hidden="true"></i>
              </a>
            </li>
          </ul>
        </div>
      </div>
      {/* <!-- promo pannel end-->
					<!-- slider nav start--> */}
      <div className="slider__nav slider__nav--promo">
        <div className="promo-slider__count"></div>
        <div className="slider__arrows">
          <div className="slider__prev">
            <i className="fa fa-chevron-left" aria-hidden="true"></i>
          </div>
          <div className="slider__next">
            <i className="fa fa-chevron-right" aria-hidden="true"></i>
          </div>
        </div>
      </div>
      {/* <!-- slider nav end--> */}
    </section>
  );
};

export default PromoBanners;
