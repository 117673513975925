import React from "react";
import { SOCIAL_MEDIA_URLS } from "../../utility/constant";

const JoinUsPopup = (props) => {
  return (
    <div
      id="joinUsModal"
      className="modal fade"
      role="dialog"
      data-backdrop="static"
    >
      <div className="modal-dialog">
        {/* <!-- Modal content--> */}
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title">Registrations Closed!</h4>
            <button type="button" className="close" data-dismiss="modal">
              &times;
            </button>
          </div>
          <div className="modal-body">
            <p>
              The registration for the year 2022 - 2023 are now closed. We are
              sorry that you were not able to register this year. We will be
              back next year and you could try to register then.
            </p>
          </div>
          <div className="modal-footer join-us-modal-footer">
            {/* <button type="button" className="btn btn-default" data-dismiss="modal">Close</button> */}
            <h6>Follow Us On</h6>
            <ul className="footer-socials join-us-socials">
              <li className="footer-socials__item">
                <a
                  className="footer-socials__link"
                  target={"_blank"}
                  rel="noreferrer"
                  href={SOCIAL_MEDIA_URLS.INSTAGRAM}
                >
                  <i className="fa fa-instagram" aria-hidden="true"></i>
                </a>
              </li>
              <li className="footer-socials__item">
                <a
                  className="footer-socials__link"
                  target={"_blank"}
                  rel="noreferrer"
                  href={SOCIAL_MEDIA_URLS.YOUTUBE}
                >
                  <i className="fa fa-youtube" aria-hidden="true"></i>
                </a>
              </li>
              {/* <li className="footer-socials__item"><a className="footer-socials__link" href="#"><i className="fa fa-google-plus" aria-hidden="true"></i></a></li> */}
              <li className="footer-socials__item">
                <a
                  className="footer-socials__link"
                  target={"_blank"}
                  rel="noreferrer"
                  href={SOCIAL_MEDIA_URLS.FB}
                >
                  <i className="fa fa-facebook" aria-hidden="true"></i>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default JoinUsPopup;
