import React from "react";

const TermsAndConditions = (props) => {
  return (
    <section className="section events" id="privacy-policy">
      <div className="container">
        <div className="row margin-bottom">
          <div className="col-12">
            <div className="heading heading--primary heading--center">
              <span className="heading__pre-title">
                LAST REVISION: 14 May, 2022
              </span>
              <br />
              <span className="heading__pre-title">Terms &amp; Conditions</span>
              <h2 className="heading__title">
                <span>RYA</span> <span>TERMS OF SERVICE AGREEMENT</span>
              </h2>
            </div>
          </div>
        </div>
        <p className="no-margin-bottom">
          PLEASE READ THIS TERMS OF SERVICE AGREEMENT CAREFULLY. BY USING THIS
          WEBSITE OR ORDERING PRODUCTS FROM THIS WEBSITE YOU AGREE TO BE BOUND
          BY ALL OF THE TERMS AND CONDITIONS OF THIS AGREEMENT.
        </p>
        <p className="no-margin-bottom">
          This Terms of Service Agreement (the "Agreement") governs your use of
          this website, www.ryametro.com (the "Website"), RYA Madras Metro Trust
          ("RYA Metro") offer of products for purchase on this Website, or your
          purchase of products available on this Website. This Agreement
          includes, and incorporates by this reference, the policies and
          guidelines referenced below. RYA Metro reserves the right to change or
          revise the terms and conditions of this Agreement at any time by
          posting any changes or a revised Agreement on this Website. RYA Metro
          will alert you that changes or revisions have been made by indicating
          on the top of this Agreement the date it was last revised. The changed
          or revised Agreement will be effective immediately after it is posted
          on this Website. Your use of the Website following the posting any
          such changes or of a revised Agreement will constitute your acceptance
          of any such changes or revisions. [RYA Metro] encourages you to review
          this Agreement whenever you visit the Website to make sure that you
          understand the terms and conditions governing use of the Website. This
          Agreement does not alter in any way the terms or conditions of any
          other written agreement you may have with [RYA Metro] for other
          products or services. If you do not agree to this Agreement (including
          any referenced policies or guidelines), please immediately terminate
          your use of the Website. If you would like to print this Agreement,
          please click the print button on your browser toolbar.
        </p>
        <span className="mt-5">
          <h4>I. PRODUCTS</h4>
          <p className="no-margin-bottom">
            Terms of Offer. This Website offers for sale certain products (the
            "Products"). By placing an order for Products through this Website,
            you agree to the terms set forth in this Agreement.
          </p>
          <p className="no-margin-bottom">
            Customer Solicitation: Unless you notify our third party call center
            reps or direct [RYA Metro] sales reps, while they are calling you,
            of your desire to opt out from further direct company communications
            and solicitations, you are agreeing to continue to receive further
            emails and call solicitations [RYA Metro] and its designated in
            house or third party call team(s).
          </p>
          <p className="no-margin-bottom">
            Opt Out Procedure: We provide 3 easy ways to opt out of from future
            solicitations. 1. You may use the opt out link found in any email
            solicitation that you may receive. 2. You may also choose to opt
            out, via sending your email address to: updates@ryametro.com
          </p>
          <p className="no-margin-bottom">
            3. You may send a written remove request to{" "}
          </p>
          <p className="no-margin-bottom">
            RYA Madras Metro Trust
            <br />
            12, Saravana St, Parthasarathi Puram, T. Nagar, Chennai, Tamil Nadu
            600017.
          </p>
          <p className="no-margin-bottom">
            Proprietary Rights. [RYA Metro] has proprietary rights and trade
            secrets in the Products. You may not copy, reproduce, resell or
            redistribute any Product manufactured and/or distributed by [RYA
            Metro]. [RYA Metro] also has rights to all trademarks and trade
            dress and specific layouts of this webpage, including calls to
            action, text placement, images and other information.
          </p>
          <p className="no-margin-bottom">
            Sales Tax. If you purchase any Products, you will be responsible for
            paying any applicable sales tax.
          </p>
        </span>
        <span>
          <h4>II. WEBSITE</h4>
          <p className="no-margin-bottom">
            Content; Intellectual Property; Third Party Links. In addition to
            making Products available, this Website also offers information and
            marketing materials. This Website also offers information, both
            directly and through indirect links to third-party websites, about
            nutritional and dietary supplements. [RYA Metro] does not always
            create the information offered on this Website; instead the
            information is often gathered from other sources. To the extent that
            [RYA Metro] does create the content on this Website, such content is
            protected by intellectual property laws of the India, foreign
            nations, and international bodies. Unauthorized use of the material
            may violate copyright, trademark, and/or other laws. You acknowledge
            that your use of the content on this Website is for personal,
            noncommercial use. Any links to third-party websites are provided
            solely as a convenience to you. [RYA Metro] does not endorse the
            contents on any such third-party websites. [RYA Metro] is not
            responsible for the content of or any damage that may result from
            your access to or reliance on these third-party websites. If you
            link to third-party websites, you do so at your own risk.
          </p>
          <p className="no-margin-bottom">
            Customer Solicitation: Unless you notify our third party call center
            reps or direct [RYA Metro] sales reps, while they are calling you,
            of your desire to opt out from further direct company communications
            and solicitations, you are agreeing to continue to receive further
            emails and call solicitations [RYA Metro] and its designated in
            house or third party call team(s).
          </p>
          <p className="no-margin-bottom">
            Use of Website; [RYA Metro] is not responsible for any damages
            resulting from use of this website by anyone. You will not use the
            Website for illegal purposes. You will (1) abide by all applicable
            local, state, national, and international laws and regulations in
            your use of the Website (including laws regarding intellectual
            property), (2) not interfere with or disrupt the use and enjoyment
            of the Website by other users, (3) not resell material on the
            Website, (4) not engage, directly or indirectly, in transmission of
            "spam", chain letters, junk mail or any other type of unsolicited
            communication, and (5) not defame, harass, abuse, or disrupt other
            users of the Website
          </p>
          <p className="no-margin-bottom">
            License. By using this Website, you are granted a limited,
            non-exclusive, non-transferable right to use the content and
            materials on the Website in connection with your normal,
            noncommercial, use of the Website. You may not copy, reproduce,
            transmit, distribute, or create derivative works of such content or
            information without express written authorization from [RYA Metro]
            or the applicable third party (if third party content is at issue).
          </p>
          <p className="no-margin-bottom">
            Posting. By posting, storing, or transmitting any content on the
            Website, you hereby grant [RYA Metro] a perpetual, worldwide,
            non-exclusive, royalty-free, assignable, right and license to use,
            copy, display, perform, create derivative works from, distribute,
            have distributed, transmit and assign such content in any form, in
            all media now known or hereinafter created, anywhere in the world.
            [RYA Metro] does not have the ability to control the nature of the
            user-generated content offered through the Website. You are solely
            responsible for your interactions with other users of the Website
            and any content you post. [RYA Metro] is not liable for any damage
            or harm resulting from any posts by or interactions between users.
            [RYA Metro] reserves the right, but has no obligation, to monitor
            interactions between and among users of the Website and to remove
            any content [RYA Metro] deems objectionable, in MuscleUP Nutrition
            's sole discretion.
          </p>
        </span>
        <span>
          <h4>III. DISCLAIMER OF WARRANTIES</h4>
          <p className="no-margin-bottom">
            YOUR USE OF THIS WEBSITE AND/OR PRODUCTS ARE AT YOUR SOLE RISK. THE
            WEBSITE AND PRODUCTS ARE OFFERED ON AN "AS IS" AND "AS AVAILABLE"
            BASIS. [RYA Metro] EXPRESSLY DISCLAIMS ALL WARRANTIES OF ANY KIND,
            WHETHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, IMPLIED
            WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND
            NON-INFRINGEMENT WITH RESPECT TO THE PRODUCTS OR WEBSITE CONTENT, OR
            ANY RELIANCE UPON OR USE OF THE WEBSITE CONTENT OR PRODUCTS.
            ("PRODUCTS" INCLUDE TRIAL PRODUCTS.)
          </p>
          <p className="no-margin-bottom">
            WITHOUT LIMITING THE GENERALITY OF THE FOREGOING, [RYA Metro] MAKES
            NO WARRANTY:
          </p>
          <p className="no-margin-bottom">
            THAT THE INFORMATION PROVIDED ON THIS WEBSITE IS ACCURATE, RELIABLE,
            COMPLETE, OR TIMELY.
          </p>
          <p className="no-margin-bottom">
            THAT THE LINKS TO THIRD-PARTY WEBSITES ARE TO INFORMATION THAT IS
            ACCURATE, RELIABLE, COMPLETE, OR TIMELY.
          </p>
          <p className="no-margin-bottom">
            NO ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED BY YOU
            FROM THIS WEBSITE WILL CREATE ANY WARRANTY NOT EXPRESSLY STATED
            HEREIN.
          </p>
          <p className="no-margin-bottom">
            AS TO THE RESULTS THAT MAY BE OBTAINED FROM THE USE OF THE PRODUCTS
            OR THAT DEFECTS IN PRODUCTS WILL BE CORRECTED.
          </p>
          <p className="no-margin-bottom">
            REGARDING ANY PRODUCTS PURCHASED OR OBTAINED THROUGH THE WEBSITE.
          </p>
          <p className="no-margin-bottom">
            SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF CERTAIN WARRANTIES,
            SO SOME OF THE ABOVE EXCLUSIONS MAY NOT APPLY TO YOU.
          </p>
        </span>
        <span>
          <h4>IV. LIMITATION OF LIABILITY</h4>
          <p className="no-margin-bottom">
            [RYA Metro] ENTIRE LIABILITY, AND YOUR EXCLUSIVE REMEDY, IN LAW, IN
            EQUITY, OR OTHWERWISE, WITH RESPECT TO THE WEBSITE CONTENT AND
            PRODUCTS AND/OR FOR ANY BREACH OF THIS AGREEMENT IS SOLELY LIMITED
            TO THE AMOUNT YOU PAID, LESS SHIPPING AND HANDLING, FOR PRODUCTS
            PURCHASED VIA THE WEBSITE.
          </p>
          <p className="no-margin-bottom">
            [RYA Metro] WILL NOT BE LIABLE FOR ANY DIRECT, INDIRECT, INCIDENTAL,
            SPECIAL OR CONSEQUENTIAL DAMAGES IN CONNECTION WITH THIS AGREEMENT
            OR THE PRODUCTS IN ANY MANNER, INCLUDING LIABILITIES RESULTING FROM
            (1) THE USE OR THE INABILITY TO USE THE WEBSITE CONTENT OR PRODUCTS;
            (2) THE COST OF PROCURING SUBSTITUTE PRODUCTS OR CONTENT; (3) ANY
            PRODUCTS PURCHASED OR OBTAINED OR TRANSACTIONS ENTERED INTO THROUGH
            THE WEBSITE; OR (4) ANY LOST PROFITS YOU ALLEGE.
          </p>
          <p className="no-margin-bottom">
            SOME JURISDICTIONS DO NOT ALLOW THE LIMITATION OR EXCLUSION OF
            LIABILITY FOR INCIDENTAL OR CONSEQUENTIAL DAMAGES SO SOME OF THE
            ABOVE LIMITATIONS MAY NOT APPLY TO YOU.
          </p>
        </span>
        <span>
          <h4>V. INDEMNIFICATION</h4>
          <p className="no-margin-bottom">
            You will release, indemnify, defend and hold harmless [RYA Metro],
            and any of its contractors, agents, employees, officers, directors,
            shareholders, affiliates and assigns from all liabilities, claims,
            damages, costs and expenses, including reasonable attorneys' fees
            and expenses, of third parties relating to or arising out of (1)
            this Agreement or the breach of your warranties, representations and
            obligations under this Agreement; (2) the Website content or your
            use of the Website content; (3) the Products or your use of the
            Products (including Trial Products); (4) any intellectual property
            or other proprietary right of any person or entity; (5) your
            violation of any provision of this Agreement; or (6) any information
            or data you supplied to [RYA Metro]. When [RYA Metro] is threatened
            with suit or sued by a third party, [RYA Metro] may seek written
            assurances from you concerning your promise to indemnify [RYA
            Metro]; your failure to provide such assurances may be considered by
            [RYA Metro] to be a material breach of this Agreement. [RYA Metro]
            will have the right to participate in any defense by you of a
            third-party claim related to your use of any of the Website content
            or Products, with counsel of [RYA Metro] choice at its expense. [RYA
            Metro] will reasonably cooperate in any defense by you of a
            third-party claim at your request and expense. You will have sole
            responsibility to defend [RYA Metro] against any claim, but you must
            receive [RYA Metro] prior written consent regarding any related
            settlement. The terms of this provision will survive any termination
            or cancellation of this Agreement or your use of the Website or
            Products.
          </p>
        </span>
        <span>
          <h4>VI. PRIVACY</h4>
          <p className="no-margin-bottom">
            [RYA Metro] believes strongly in protecting user privacy and
            providing you with notice of MuscleUP Nutrition 's use of data.
            Please refer to [RYA Metro] privacy policy, incorporated by
            reference herein, that is posted on the Website.
          </p>
        </span>
        <span>
          <h4>VI. AGREEMENT TO BE BOUND</h4>
          <p className="no-margin-bottom">
            By using this Website or ordering Products, you acknowledge that you
            have read and agree to be bound by this Agreement and all terms and
            conditions on this Website.
          </p>
        </span>
        <span>
          <h4>VIII. GENERAL</h4>
          <p className="no-margin-bottom">
            Force Majeure. [RYA Metro] will not be deemed in default hereunder
            or held responsible for any cessation, interruption or delay in the
            performance of its obligations hereunder due to earthquake, flood,
            fire, storm, natural disaster, act of God, war, terrorism, armed
            conflict, labor strike, lockout, or boycott.
          </p>
          <p className="no-margin-bottom">
            Cessation of Operation. [RYA Metro] may at any time, in its sole
            discretion and without advance notice to you, cease operation of the
            Website and distribution of the Products.
          </p>
          <p className="no-margin-bottom">
            Entire Agreement. This Agreement comprises the entire agreement
            between you and [RYA Metro] and supersedes any prior agreements
            pertaining to the subject matter contained herein.
          </p>
          <p className="no-margin-bottom">
            Effect of Waiver. The failure of [RYA Metro] to exercise or enforce
            any right or provision of this Agreement will not constitute a
            waiver of such right or provision. If any provision of this
            Agreement is found by a court of competent jurisdiction to be
            invalid, the parties nevertheless agree that the court should
            endeavor to give effect to the parties' intentions as reflected in
            the provision, and the other provisions of this Agreement remain in
            full force and effect.
          </p>
          <p className="no-margin-bottom">
            Governing Law; Chennai Jurisdiction. This Website originates from
            the Chennai, Tamil Nadu. This Agreement will be governed by the laws
            of the State of Tamil Nadu, without regard to its conflict of law
            principles to the contrary. Neither you nor [RYA Metro] will
            commence or prosecute any suit, proceeding or claim to enforce the
            provisions of this Agreement, to recover damages for breach of or
            default of this Agreement, or otherwise arising under or by reason
            of this Agreement, other than in courts located in State of Tamil
            Nadu. By using this Website or ordering Products, you consent to the
            jurisdiction and venue of such courts in connection with any action,
            suit, proceeding or claim arising under or by reason of this
            Agreement. You hereby waive any right to trial by jury arising out
            of this Agreement and any related documents.
          </p>
          <p className="no-margin-bottom">
            Statute of Limitation. You agree that regardless of any statute or
            law to the contrary, any claim or cause of action arising out of or
            related to use of the Website or Products or this Agreement must be
            filed within one (1) year after such claim or cause of action arose
            or be forever barred.
          </p>
          <p className="no-margin-bottom">
            Waiver of Class Action Rights. BY ENTERING INTO THIS AGREEMENT, YOU
            HEREBY IRREVOCABLY WAIVE ANY RIGHT YOU MAY HAVE TO JOIN CLAIMS WITH
            THOSE OF OTHER IN THE FORM OF A CLASS ACTION OR SIMILAR PROCEDURAL
            DEVICE. ANY CLAIMS ARISING OUT OF, RELATING TO, OR CONNECTION WITH
            THIS AGREEMENT MUST BE ASSERTED INDIVIDUALLY.
          </p>
          <p className="no-margin-bottom">
            Termination. [RYA Metro] reserves the right to terminate your access
            to the Website if it reasonably believes, in its sole discretion,
            that you have breached any of the terms and conditions of this
            Agreement. Following termination, you will not be permitted to use
            the Website and [RYA Metro] may, in its sole discretion and without
            advance notice to you, cancel any outstanding orders for Products.
            If your access to the Website is terminated, [RYA Metro] reserves
            the right to exercise whatever means it deems necessary to prevent
            unauthorized access of the Website. This Agreement will survive
            indefinitely unless and until [RYA Metro] chooses, in its sole
            discretion and without advance to you, to terminate it.
          </p>
          <p className="no-margin-bottom">
            Domestic Use. [RYA Metro] makes no representation that the Website
            or Products are appropriate or available for use in locations
            outside India. Users who access the Website from outside India do so
            at their own risk and initiative and must bear all responsibility
            for compliance with any applicable local laws.
          </p>
          <p className="no-margin-bottom">
            Assignment. You may not assign your rights and obligations under
            this Agreement to anyone. [RYA Metro] may assign its rights and
            obligations under this Agreement in its sole discretion and without
            advance notice to you.
          </p>
          <p className="no-margin-bottom">
            BY USING THIS WEBSITE OR ORDERING PRODUCTS FROM THIS WEBSITE YOU
          </p>
          <p className="no-margin-bottom">
            AGREE TO BE BOUND BY ALL OF THE TERMS AND CONDITIONS OF THIS
            AGREEMENT.
          </p>
        </span>
      </div>
    </section>
  );
};

export default TermsAndConditions;
