import React from "react";
import { Helmet } from "react-helmet";

const GooglePhotosEmbeded = (props) => {
  return (
    <>
      <Helmet>
        <script
          src="https://cdn.jsdelivr.net/npm/publicalbum@latest/embed-ui.min.js"
          async
        ></script>
      </Helmet>
      <section className="section">
        <div className="col-lg-6 col-xl-8 projects-masonry__item projects-masonry__item--height-2 projects-masonry__item--primary projects-masonry__item--heading event-gallery-title-section">
          <div className="projects-masonry__img">
            <div className="heading heading--primary">
              <span className="heading__pre-title">RYA Metro</span>
              <h2 className="heading__title">
                <span>Event</span> <span>Gallery</span>
              </h2>
              {/* <p>
                Rya Metro has conducted various kinds of event like social,
                educational, entertainment programs.
              </p> */}
            </div>
          </div>
        </div>
        <div
          class="pa-gallery-player-widget"
          style={{
            width: "90%",
            height: "480px",
            display: "none",
            margin: "0 auto",
          }}
          data-link="https://photos.app.goo.gl/QQu94cKKPvQPQn9g8"
          data-title="RYA Metro Reminiscences"
          data-description="24 new items added to shared album"
        >
          <object data="https://lh3.googleusercontent.com/NqzmSSyHn1OdzWWqsoj0AOpIhWdkFGBDomfnTcR5xcKZA63asb15FFPNCazKW4sh14PvXCfTB5_YjVRBK7fXN7jW0pKXQ1QyU2arEp8gN7ILwIUftIdl3Mt6481Gxxd6lmdSjCA2bbM=w1920-h1080"></object>
          <object data="https://lh3.googleusercontent.com/6fdzbRIZ4RYn8X0vH9kyunKgxFhruSXkNaaA176NVFc-wV32g7C540jE_7NAW6Pgwu4WUapWDN1ipA_lg2hJUF7H2ShPHjO-yS4DeXoqRQmCFViXIfuI9Bc9A9ZFw1VtwxTjWSehIa8=w1920-h1080"></object>
          <object data="https://lh3.googleusercontent.com/qDQ2jTsmAGKNHa_VgMZL7d9tRueN2ptx6GssreyoCOkMjtRvKyA77m_aIMcbWbDG33i3bRFJtG_BlnttpdjYfNF88JiN7CamZnirYhwKAY92AVIKdpR3MhJgeK1OGJeZ1s4Ra5I6tn0=w1920-h1080"></object>
          <object data="https://lh3.googleusercontent.com/Y_I-Aj3pdlZJTr6g1XQbVXG6rNCUHi5PoAFwyxHHLiogDKNnqXVhcc7NXylu84IBdOMHQs3CuxWcVFCOTTRRanJMex093ROD3lf1N2VpF0hhpKm3KBpN0J4rbDC-vLtIw6PCJfGQR2I=w1920-h1080"></object>
          <object data="https://lh3.googleusercontent.com/IhaWJJfhAK_7Iiqyb9PsPdalr8s_hjO6jEyN1bLt63j9C7AeKq5kYIcLdJ-Rlx8En3iLeLmMTzSIQrxNIiDFCQR2mPlS5s2kYLIlORyIQIZhLkUvZpBaowdpj0MDkydBAm7DqlSXOlE=w1920-h1080"></object>
          <object data="https://lh3.googleusercontent.com/ePgJ3LenZ2uUO6D3RmlV4zcA40oiJHwKMzEQdFKb79pgwW6koFWjOpdRGlD-J4oD8LpCy3gmY8gIPsrYLbRMdL_-Jor04dwMdY8q2TZzP7sLrzxaSEPC_URWPaLALM14tOLrNJ_Qxy4=w1920-h1080"></object>
          <object data="https://lh3.googleusercontent.com/V-0qneTiM25y6vJft_GcGD8_sbdIIe5bJDvOp4v4HI3imoIK2LfkotvzjPhnnWz3-hUGbugIghJ4SMLEUMqnrpe2AsOz0eLEXZXTONlWb__PSiwbJ4AhWInCrkjDFGMs2fkusGjm6GA=w1920-h1080"></object>
          <object data="https://lh3.googleusercontent.com/odOm6crNU6k3bbvprxmlqNmMayjtTZwMpwF4f0sdHrjAOc-4JjgSArs5G2ECscZOeLaoBHiC42Z-_T37BELeWOI9gIWTrk-bvUUt3H6-Xul6GlBwwwlCT9-XliYSUprOuIoPZqrROss=w1920-h1080"></object>
          <object data="https://lh3.googleusercontent.com/qCJlRjSYtPtdvY6-vJI_nta746FGXvV-YYdv8o2iIznhwJyTmq3sOSnXPFiW-OVqoU6NrxmdbNkBnpVqaPppIPPnMl6yM7t7UpZLmUjy9j4aG7LyQLsWBaBoLxVP9xRDnZUOurChV7Y=w1920-h1080"></object>
          <object data="https://lh3.googleusercontent.com/4EL8_PDA6mNK2AjdGrGmyFAbHWGgVelQgb9rQgbUTd94oz2GVfGAjKwspOwiGUcsJX6Ul37Eza_Ik8nyTDauaZhQfxuNTXbcl3tHObVjPkDdhrpYlPgD0d9Y7om1jsk9qGTVG7sGGGY=w1920-h1080"></object>
          <object data="https://lh3.googleusercontent.com/s9zRLp_pBz66RwpYR8syk9hIxsNd-ZwQnxyvX5bhj9gqPm8DPBYdCKPV60MX6Kt4LaRjuqFgIP6udjDaaea2nYPHqt90fcgXKoLg1mh2CTN2dZBKSfwqDkPPpFvqmE1pV2NLlTp6r7Q=w1920-h1080"></object>
          <object data="https://lh3.googleusercontent.com/XTpHG1x3eriMakEA44plOZ3Gv_8B2vpD3IyGERP7W6lK1b5EI-BCBs0STcFzXRIffcQ5OuRWjA_GPgXa-3MOPMlVbmJX3An2H3tLnXW_pTxiNjGkXPBDVzsmRK8Qfsb8ncsMOqZecS8=w1920-h1080"></object>
          <object data="https://lh3.googleusercontent.com/LoEZb3ZgEObfKVZTptfkhYW2rTgxqmUxnWJveikAo_P0BK9jz0eebl3xzsiCJe7oydxL7ouXjY6UJ5eCUivDUbij0-omi1N9y7rnmOfMZDy6hNyl8KOT2fKJ5SAbeGqVdNR1_36CBE0=w1920-h1080"></object>
          <object data="https://lh3.googleusercontent.com/JbBXihM_Bs8-pLdROQTSmxjlGE3MAKgSEasPCJQmYcF22V_A01T2fHA8c3ATg-WXunYaSGnXjqYHuyqEubbp1x33iM956txSDefhq-ebE0LuTJYk-qEioxyT4DaGYZ0LlMzb73O1jEk=w1920-h1080"></object>
          <object data="https://lh3.googleusercontent.com/lJKhJT16M914awBJKg13jySx79m6sbdVPNqt_9cp5seijluABlVoF5vpcg7a6MSF1N-V8GdsCXr0jz2PMqphQoVuGxabu-kmRoyaXoKppqkEgdw6UtJ7XpVZRfoQWnsOHDWQcLkYJz8=w1920-h1080"></object>
          <object data="https://lh3.googleusercontent.com/yosxku66bNyR1IFazHqSro8tv0Qdm45s3NM_0h6Q9PFZ4nOSe0LXjukwYSV0AQfekHxMN2r_B4CVt67opodstSm-RqqO8peMt-iz0wSHFcT6HINR0qN8J17Q5uzqRSxAUn2Mupvwa8w=w1920-h1080"></object>
          <object data="https://lh3.googleusercontent.com/dqxqCL_ulyqPn3CkmTg0uoJAZ1pKNU_4bpjk9CZx2QmxXxbCP_dvP8gAm4ge8W6cBZjO1nouVKMd_OafapoSEDLRGg_SXx568Pb-UIXWeXqX5CHcZmmZewvTxYaR7PuFd--wIMRtGu0=w1920-h1080"></object>
          <object data="https://lh3.googleusercontent.com/TEceCw0CpO20OvM1jD42lmH4OYlWU-NaI-xN4sqq6D64wX2SemuAvCeTViaIXV0JCQIjgGDcrAH2CeSh8YDqmzUPqYOYk3uPwp1gJ5ETJ9SfLKmtdgRnPRTByKSMFXVtubVE6JR5lC8=w1920-h1080"></object>
          <object data="https://lh3.googleusercontent.com/vwknngDeBGSh2kgaDqIB33GbpIjLeDaVI-ttd5JiwGZ-5bzznP4IkdNzJRd3MupTbPuPvE7VCEPQ6YyE99QqWyWEBhMDfwpe4pkZVjwrt_ZsDmO1VOFkybf_6sawf581FCBsmDPLoPo=w1920-h1080"></object>
          <object data="https://lh3.googleusercontent.com/M9Px-YljgtvnEBECRWNiHAWQFXVDl95VD3IGXZOkDOHf3J9T_ZEIs0iEs6FWwPyxqqWXQEmZnxtNFDgPbYmKoIp0YQOZFsyzUouX74UF2UzNlN-LzHmUYmABqbieA2OgPAln7GAXYgw=w1920-h1080"></object>
          <object data="https://lh3.googleusercontent.com/A2p9BRffK9-9_lC9KTd_4WZ40edzO4ust7wp9Tl7YxhGSo00duXrHhbAwZwME8GWiFx-Tkp9VEzxnZJnwMd3Gv6U0AL2SDfds2oYL9v_evYlOibcSnrS3ccpwSFGTJ8NGrM3h3YXe14=w1920-h1080"></object>
          <object data="https://lh3.googleusercontent.com/JzQ57zITDjIbcyb-wnaYo92uDx8UJ1kW8p9ylahV0GtVhkmGaG4KitS-fGEsdVrzr0QaAQWk4Fge9Y3uaZph8Lure68ExanhWtwJTVn7nTXhToCYKqjhjJRm8bsWwQFQFS4ncrR7kg8=w1920-h1080"></object>
          <object data="https://lh3.googleusercontent.com/cxzzAPCHnGpKn0hpCQZgOjlx68sSrNXos4tAY6S8LvUikeAZdklvRJHj5n9H7JnbWnaYohe2K__ev291-95MBKBTdg9kNbyKH9iiB0wa4hgKQlFZ2HKFhqfKYMws1QmTlzNCkZCO_yg=w1920-h1080"></object>
          <object data="https://lh3.googleusercontent.com/AHvOm8nD0TWCa4Ys4ALCCQqRNMkvbc8CXu4HXVPjnNqgdTrxrY8GR35DLWx3Xj3QPjpTavduAhHlVu-DwkGE_xpYsMheDJmtIPSI2tWHbnL8jDOd_FtyVYGGf8al-IO04rPPhhlikag=w1920-h1080"></object>
        </div>
      </section>
    </>
  );
};

export default GooglePhotosEmbeded;
