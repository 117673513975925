import React, { useEffect } from "react";
import { useLocation } from "react-router";
import $ from 'jquery'

const ScrollToTop = props => {
    const location = useLocation();

    useEffect(() => {
        if (window.location.hash) {
            window.scrollTo(0, $(window.location.hash)[0].offsetTop);
        } else {
            window.scrollTo(0, 0);
        }

    }, [location]);

    return (
        <>{props.children}</>
    )
}

export default ScrollToTop