import React from "react";
import { routes, socialProjectsItems } from "../../utility/constant";
import { baseImagePath } from "../../utility/functions";
// import { baseImagePath } from "../../utility/functions";
import "./SocialProjects.css";

const SocialProjects = (props) => {
  return (
    <>
      <section className="promo-primary">
        <picture>
          <source
            srcSet={baseImagePath("custom/social-projects-banner.jpg")}
            media="(min-width: 992px)"
          />
          <img
            className="img--bg"
            src={baseImagePath("custom/social-projects-banner.jpg")}
            alt="img"
          />
        </picture>
        <div className="promo-primary__description">
          {" "}
          <span>RYA METRO</span>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-auto">
              <div className="align-container">
                <div className="align-container__item">
                  <span className="promo-primary__pre-title">
                    BECOME A DONOR, WE NEED YOUR SUPPORT
                  </span>
                  <h1 className="promo-primary__title">
                    <span>Social Projects</span>
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section events social-projects-section" id={routes.SOCIAL_PROJECTS_HASH}>
        <div className="container">
          {/* <div className="row margin-bottom">
            <div className="col-12">
              <div className="heading heading--primary heading--center">
                <span className="heading__pre-title custom_pre-title">
                  BECOME A DONOR, WE NEED YOUR SUPPORT
                </span>
                <h2 className="heading__title">
                  <span>RYA</span> <span>Social Projects</span>
                </h2>
                <p className="no-margin-bottom">Sharksucker sea toad candiru rocket danio tilefish stingray deepwater stingray Sacramento splittail, Canthigaster rostrata. Midshipman dartfish</p>
              </div>
            </div>
          </div> */}
          <article id="mz-accordion">
            <ul>
              {socialProjectsItems.map((i, index) => {
                if (index === 0) {
                  return (
                    <li key={index}>
                      <details open>
                        <summary>
                          <p>{i.title}</p>
                        </summary>
                        <p>{i.description}</p>
                        {i.link && i.linkText ? (
                          <a
                            className="button button--filled mt-5"
                            target={"_blank"}
                            rel="noreferrer"
                            href={i.link}
                          >
                            {i.linkText}
                          </a>
                        ) : null}
                      </details>
                    </li>
                  );
                } else {
                  return (
                    <li key={index}>
                      <details>
                        <summary>
                          <p>{i.title}</p>
                        </summary>
                        <p>{i.description}</p>
                        {i.link && i.linkText ? (
                          <a
                            className="button button--filled mt-5"
                            target={"_blank"}
                            rel="noreferrer"
                            href={i.link}
                            style={{
                              width: "30%",
                              margin: "0 auto",
                            }}
                          >
                            {i.linkText}
                          </a>
                        ) : null}
                      </details>
                    </li>
                  );
                }
              })}
            </ul>
          </article>
          <div className="row">
            <div className="col-12 text-center">
              <a
                className="button button--blue"
                target={"_blank"}
                rel="noreferrer"
                href={routes.CHENNAI_FOOD_BANK}
              >
                Know More About Us
              </a>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SocialProjects;
