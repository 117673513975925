import React from "react";

const ReturnRefund = (props) => {
  return (
    <section className="section events" id="privacy-policy">
      <div className="container">
        <div className="row margin-bottom">
          <div className="col-12">
            <div className="heading heading--primary heading--center">
              {/* <span className="heading__pre-title">Events</span> */}
              <h2 className="heading__title">
                <span>RYA</span> <span>Return &amp; Refunds</span>
              </h2>
            </div>
          </div>
        </div>
        <h4 className="no-margin-bottom text-center">
          Currently RYA doesn't accepts any refunds or returns
        </h4>
      </div>
    </section>
  );
};

export default ReturnRefund;
