import React from "react";
import { infrastructureBannerImages, routes } from "../../utility/constant";
import { baseImagePath } from "../../utility/functions";
import "./Infrastructure.css";

const Infrastructure = (props) => {
  return (
    <>
      <section className="infrastructure-carousel-wrapper">
        <div className="infrastructure-carousel">
          <div className="ombre-externe">
            <div className="ombre-interne">
              <div
                id="carouselExampleCaptions"
                className="carousel slide "
                data-bs-ride="carousel"
              >
                <div className="carousel-inner">
                  {infrastructureBannerImages.map((i, index) => {
                    let imgPath = baseImagePath(i);
                    if (
                      (i && i.includes("http")) ||
                      i.includes("https") ||
                      i.includes("www")
                    ) {
                      imgPath = i;
                    }
                    return (
                      <div
                        className={
                          index === 0 ? "carousel-item active" : "carousel-item"
                        }
                        key={index}
                      >
                        <img
                          src={imgPath}
                          className="d-block w-100 peinture-ombre-interne-fine infrastructure-img"
                          alt="..."
                        />
                        {/* <div className="carousel-caption">
                          <h5>First slide</h5>
                          <p></p>
                        </div> */}
                      </div>
                    );
                  })}
                </div>
                <button
                  className="carousel-control-prev"
                  type="button"
                  data-bs-target="#carouselExampleCaptions"
                  data-bs-slide="prev"
                >
                  <span
                    className="carousel-control-prev-icon"
                    aria-hidden="true"
                  ></span>
                  <span className="visually-hidden">Previous</span>
                </button>
                <button
                  className="carousel-control-next"
                  type="button"
                  data-bs-target="#carouselExampleCaptions"
                  data-bs-slide="next"
                >
                  <span
                    className="carousel-control-next-icon"
                    aria-hidden="true"
                  ></span>
                  <span className="visually-hidden">Next</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        className="section events infrastructure-section"
        id={routes.INFRASTRUCTURE_HASH}
      >
        <div className="container">
          <div className="row margin-bottom">
            <div className="col-12">
              <div className="heading heading--primary heading--center">
                {/* <span className="heading__pre-title">Events</span> */}
                <h2 className="heading__title">
                  <span>Premium Space in T. Nagar, Chennai available for </span>{" "}
                  <br />
                  <span
                    style={{
                      fontWeight: 700,
                      fontSize: "24px",
                      letterSpacing: 0,
                    }}
                  >
                    Training | Seminars | Meetings | Workshops | Gatherings
                  </span>
                </h2>
                {/* <p className="no-margin-bottom">
                  Sharksucker sea toad candiru rocket danio tilefish stingray
                  deepwater stingray Sacramento splittail, Canthigaster
                  rostrata. Midshipman dartfish
                </p> */}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 col-lg-4">
              <div className="event-item">
                {/* <div className="event-item__img">
                  <img
                    className="img--bg"
                    src={baseImagePath("theme/event_1.jpg")}
                    alt="img"
                  />
                </div> */}
                <div className="event-item__content">
                  <h6 className="event-item__title">
                    <a href={`#reservation`}>VIP Lounge (10 Pax)</a>
                  </h6>
                  <p>- Lift</p>
                  <p>- Air Conditioning</p>
                  <p>- Presentation Facility</p>
                  <p>- 100% Power Backup</p>
                  <p>- Waiting Area Facility</p>
                  <p>- Refreshment Area Facility</p>
                  <p>- Wi - Fi Enabled</p>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-4">
              <div className="event-item">
                {/* <div className="event-item__img">
                  <img
                    className="img--bg"
                    src={baseImagePath("theme/event_2.jpg")}
                    alt="img"
                  />
                </div> */}
                <div className="event-item__content">
                  <h6 className="event-item__title">
                    <a href={`#reservation`}>Meeting Hall (150 pax)</a>
                  </h6>
                  <p>- Lift</p>
                  <p>- Air Conditioning</p>
                  <p>- Presentation Facility</p>
                  <p>- 100% Power Backup</p>
                  <p>- Waiting Area Facility</p>
                  <p>- Refreshment Area Facility</p>
                  <p>- Wi - Fi Enabled</p>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-4">
              <div className="event-item">
                {/* <div className="event-item__img">
                  <img
                    className="img--bg"
                    src={baseImagePath("theme/event_3.jpg")}
                    alt="img"
                  />
                </div> */}
                <div className="event-item__content">
                  <h6 className="event-item__title">
                    <a href={`#reservation`}>Dining Hall (150 pax)</a>
                  </h6>
                  <p>- Lift</p>
                  <p>- Air Conditioning</p>
                  <p>- Presentation Facility</p>
                  <p>- 100% Power Backup</p>
                  <p>- Waiting Area Facility</p>
                  <p>- Refreshment Area Facility</p>
                  <p>- Wi - Fi Enabled</p>
                </div>
              </div>
            </div>
          </div>
          <div className="row" id="reservation">
            <div className="col-12 text-center">
              <h4>
                For Reservation Please Contact <br />
                <a className="contact-button" href="tel:+914442128153">
                  +91 44 4212 8153
                </a>
              </h4>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Infrastructure;
